import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';

import { AppComponent } from './app.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { JobsComponent } from './component/jobs/jobs.component';
import { UpdatejobsComponent } from './component/jobs/updatejobs/updatejobs.component';
import { environment } from '../environments/environment';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AccessCodeGeneratorComponent } from './component/access-code-generator/access-code-generator.component';
import { LoginComponent } from './component/login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    JobsComponent,
    UpdatejobsComponent,
    AccessCodeGeneratorComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.config, 'cloud'),
    SignaturePadModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
