import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpService,
  HttpRequestModel,
} from 'src/app/service/httpservice/http.service';


@Injectable({
  providedIn: 'root'
})
export class AccessCodeGeneratorService {

  HttpRequestModel: HttpRequestModel = new HttpRequestModel();

  constructor(private httpService: HttpService) { }

  getLatestCode(): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'access_code_generator';
    this.HttpRequestModel.method = 'GET';
    this.HttpRequestModel.body = "";

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to create access code
  createAccessCode(accessCode): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'access_code_generator/add';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = accessCode;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

}
