import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export class HttpRequestModel {
  header: string;
  url: string;
  method: string;
  params: any;
  body: any;
  formType: any;

  reset() {
    this.header = '';
    this.url = '';
    this.method = '';
    this.params = '';
    this.body = '';
    this.formType = '';
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl = environment.apiUrl;

  headers = new HttpHeaders({
    'Auth-Token': environment.authToken,
    // 'Content-Type': 'application/x-www-form-urlencoded'
  });
  multipartHeaders = new HttpHeaders({
    'Auth-Token': environment.authToken,
    'Content-Type': 'multipart/form-data',
  });

  constructor(private http: HttpClient) {}

  /**
   * Request options.
   * @param options
   * @returns {RequestOptionsArgs}
   */
  private requestOptions(httpRequest: HttpRequestModel) {
    let options;
    options = httpRequest;
    // options.responseType = 'json';

    if (httpRequest.formType == 'multipart') {
      options.headers = this.headers;
    } else {
      options.headers = this.headers;
    }

    return options;
  }

  init(
    httpRequest: HttpRequestModel,
    doShowLoader = true,
    doHideloader = true
  ): Observable<any> {
    let options;
    options = this.requestOptions(httpRequest);

    return this.request(
      this.baseUrl + httpRequest.url,
      options,
      doShowLoader,
      doHideloader
    );
  }

  request(
    url: string,
    option,
    doShowLoader = true,
    doHideloader = true
  ): Observable<any> {
    // console.log(JSON.stringify(option.body));
    return this.http
      .request(option.method, url, option)
      .pipe(map((response) => response));
  }
}
