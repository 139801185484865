import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpService,
  HttpRequestModel,
} from 'src/app/service/httpservice/http.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSignatureService {
  HttpRequestModel: HttpRequestModel = new HttpRequestModel();

  constructor(private httpService: HttpService) {}

  getEmployeeSignature(searchInfo = null): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'employee_signature';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = searchInfo;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to create job
  createEmployeeSignature(employeeSignatureInfo): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'employee_signature/add';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = employeeSignatureInfo;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }
}
