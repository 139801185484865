import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/service/job/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  searchInfo = {
    search: '',
  };

  jobsData;

  constructor(private router: Router, private jobsService: JobService) {
    //Function call to get jobs data
    this.getJobs();
  }

  ngOnInit(): void {}

  getJobs() {
    //Service call to get all jobs data
    this.jobsService.getJobs(this.searchInfo).subscribe(
      (res) => this.onJobsSuccess(res),
      (error) => this.onJobsError(error)
    );
  }

  //On success of jobs api call
  onJobsSuccess(result) {
    if (result) {
      if (result.data) {
        //Assign jobs result data to jobsData
        //this.clearDatatable();
        this.jobsData = result.data;
        //this.drawDatatable();
      }
    }
  }

  //On error of jobs api call
  onJobsError(error) {}

  //Set search parameter and get filter data
  searchInTable(value, key, value1 = '', key1 = '') {
    if (value == '') {
      delete this.searchInfo[key];
    }
    if (key1 != '') {
      if (value1 != '') {
        this.searchInfo[key1] = value1;
      } else if (value1 == '') {
        delete this.searchInfo[key1];
      }
    }
    this.searchInfo[key] = value;
    this.getJobs();
  }

  //Navigate to job detail page
  goToJobDetails(id) {
    this.router.navigate(['/updatejobs', { jobId: id }]);
  }
}
