import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private renderer2: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  showToast(message: string, type: string) {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';

    if (type == 'success') {
      s.text = `successToast('` + message + `')`;
    } else if (type == 'error') {
      s.text = `dangerToast('` + message + `')`;
    } else if (type == 'warning') {
      s.text = `warningToast('` + message + `')`;
    }

    this.renderer2.appendChild(this._document.body, s);
  }
}
