import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { interval } from 'rxjs';
import { AccessCodeGeneratorService } from './service/access-code-generator/access-code-generator.service';
import { LocalstorageService } from './service/shared/localstorage/localstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pts-contracting';
  lastGeneratedCodeDetails = [];
  lastRoute;
  constructor(private router: Router, public accessCodeGeneratorService: AccessCodeGeneratorService, public localStorage: LocalstorageService) {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let url = this.router.url;
        this.lastRoute = url;
        if (!this.localStorage.getSessionItem('userCode') && url != "/accesscodegenerator03182021") {
          this.router.navigateByUrl('/login');
        }
        this.getLatestAccessCodeDetails();
        if (url == '/' || url == undefined || url == null) {
          this.router.navigateByUrl('/dashboard');
        }
      }
    });



    interval(10000).subscribe(x => {
      this.getLatestAccessCodeDetails();
    });
  }

  getLatestAccessCodeDetails() {
    this.accessCodeGeneratorService.getLatestCode().subscribe(
      (res) => this.onGetAccessCodeSuccess(res),
      (error) => this.onGetAccessCodeError(error)
    );
  }

  onGetAccessCodeSuccess(result) {
    if (result) {
      if (result.data) {
        this.lastGeneratedCodeDetails = result.data[0];
        if (this.localStorage.getSessionItem('userCode') && this.lastRoute != "/accesscodegenerator03182021") {
          if (this.lastGeneratedCodeDetails['code'] != this.localStorage.getSessionItem('userCode') && this.lastRoute != "/accesscodegenerator03182021") {
            this.router.navigateByUrl('/login');
          } else {
          }
        } else {
          if (this.lastRoute != "/accesscodegenerator03182021") {
            this.router.navigateByUrl('/login');
          }
        }
      }
    }
  }

  onGetAccessCodeError(error) {
  }
}
