import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobService } from 'src/app/service/job/job.service';
import { ToastService } from 'src/app/service/shared/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { EmployeeSignatureService } from 'src/app/service/employee-signature/employee-signature.service';
import { DOCUMENT } from '@angular/common';
import { SignaturePad } from 'angular2-signaturepad';
import { LocalstorageService } from 'src/app/service/shared/localstorage/localstorage.service';

@Component({
  selector: 'app-updatejobs',
  templateUrl: './updatejobs.component.html',
  styleUrls: ['./updatejobs.component.scss'],
})
export class UpdatejobsComponent implements OnInit {
  jobId;
  jobForm: FormGroup;
  fileList = [];
  attachSchedulefiles: File[] = [];
  task: AngularFireUploadTask;
  otherVis: false;
  confined_space_access_file_name = '';
  mobile_equipment_file_name = '';
  man_personnel_lift_file_name = '';
  forklift_file_name = '';
  high_noise_file_name = '';
  limited_access_file_name = '';

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  employeeSignatureData;
  employeeSearchInfo;

  isGeneralSafety = false;
  isMobilization = false;
  isDemobilization = false;
  isRefrigeration = false;
  isInstallationOfPiping = false;
  isInstallationOfDuctwork = false;
  isDemolition = false;
  isInsulationOfPiping = false;
  isAsbestos = false;

  isMobileEquipment = false;
  isManPersonnelLift = false;
  isForklift = false;
  isAFrameLadders = false;
  isScaffold = false;
  isRoofAccess = false;
  isHostingRigging = false;
  isTrenchAndEvacuation = false;
  isHotWork = false;
  isEnergizedSystem = false;
  isHighNoise = false;
  isLimitedAccess = false;
  isConfinedSpaceAccess = false;
  isExtremeHeat = false;
  isExposureToTraffic = false;
  isSilicaDuct = false;
  isCompressedGas = false;
  isCreatingFloorRoofPenetration = false;
  enableOtherInput = false;

  supervisorSignatureSrc;

  start_date;
  end_date;
  signature;

  description_of_work = '';
  hazards_associated_with_each_step = '';
  required_action_to_eliminate_hazard = '';
  selectedOtherIndex = '';

  EmailNotificationText = '';
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild('closeOtherModel') closeOtherModel;
  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 340,
    canvasHeight: 200,
  };

  constructor(
    private formBuilder: FormBuilder,
    private renderer2: Renderer2,
    private route: ActivatedRoute,
    private jobsService: JobService,
    @Inject(DOCUMENT) private _document,
    private toastService: ToastService,
    private storage: AngularFireStorage,
    private employeeSignatureService: EmployeeSignatureService,
    private localStorage: LocalstorageService
  ) {
    this.jobId = this.route.snapshot.paramMap.get('jobId');
    this.localStorage.setItem('savedSignature', '');
    this.employeeSearchInfo = {
      job_id: this.jobId,
      start_date: '',
      end_date: '',
    };
    this.getJob();
    this.getEmployeeSignature();
  }

  ngAfterViewInit() {
    setTimeout(() => {
    }, 3000);


  }

  clearDatatable() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.text = `clearDatatable('.jobs-data-table')`;
    this.renderer2.appendChild(this._document.body, s);
  }

  drawDatatable() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.text = `reloadDataTable('.jobs-data-table')`;
    this.renderer2.appendChild(this._document.body, s);
  }

  getJob() {
    this.jobsService.getJobById(this.jobId).subscribe(
      (res) => this.onJobGetSuccess(res),
      (error) => this.onJobGetError(error)
    );
  }

  //On success of add/get permission api
  onJobGetSuccess(result) {
    if (result) {
      if (result.data) {
        //this.toastService.showToast("Job added successfully", "success");
        // console.log(result.data);
        this.createJobForm(result.data);

        //this.generalPtp =

        // 'general_safety': [1],
        //   'mobilization': [1],
        //   "refrigeration": [0],
        //   "general_pipe_installation": [0],
        //   "duct_installation": [0],
        //   "demolition": [0],
        //   "insulation_duct_pipe": [0],
        //   "asbstos_lead_removal": [0],
        //   "demobilization": [1],

        // 'mobile_equipment': [0],
        //   'man_personnel_lift': [0],
        //   "forklift": [0],
        //   "a_frame_ladders": [0],
        //   "scaffold": [0],
        //   "roof_access": [0],
        //   "hosting_rigging": [0],
        //   "trench_and_excavation": [0],
        //   "hot_work": [0],
        //   "energized_system": [0],
        //   "high_noise": [0],
        //   "limited_access": [0],
        //   "confined_space_access": [0],
        //   "extreme_heat": [0],
        //   "other": [0],
        //   "exposure_to_traffic": [0],
        //   "slilica_duct": [0],
        //   "compressed_gas": [0],
        //   "creating_floor_roof_penetration": [0],
        //   "other1": [0]
      }
    }
  }

  //On error of add/get permission api
  onJobGetError(error) {
    this.toastService.showToast('Job Error', 'error');
  }

  ngOnInit(): void {

  }

  //To create permission form
  createJobForm(data = null) {
    if (data != null) {
      let general_and_type_of_work;
      if (data.general_and_type_of_work) {
        general_and_type_of_work = JSON.parse(data.general_and_type_of_work);
      }
      let safety_and_health_hazards;
      if (data.safety_and_health_hazards) {
        safety_and_health_hazards = JSON.parse(data.safety_and_health_hazards);
      }
      let work_permits;
      if (data.work_permits) {
        work_permits = JSON.parse(data.work_permits);

        if (work_permits.mobile_equipment_file) {
          this.mobile_equipment_file_name = work_permits.mobile_equipment_file;
        }
        if (work_permits.man_personnel_lift_file) {
          this.man_personnel_lift_file_name =
            work_permits.man_personnel_lift_file;
        }
        if (work_permits.forklift_file) {
          this.forklift_file_name = work_permits.forklift_file;
        }
        if (work_permits.high_noise_file) {
          this.high_noise_file_name = work_permits.high_noise_file;
        }
        if (work_permits.limited_access_file) {
          this.limited_access_file_name = work_permits.limited_access_file;
        }
        if (work_permits.confined_space_access_file) {
          this.confined_space_access_file_name =
            work_permits.confined_space_access_file;
        }
      }

      //Generate form group with permissin data by id
      this.jobForm = this.formBuilder.group({
        project_name: [data.project_name, Validators.required],
        job_supervisor: [data.job_supervisor, Validators.required],
        date_submitted: [
          moment(data.date_submitted).format('YYYY-MM-DD'),
          Validators.required,
        ],
        general_and_type_of_work: this.formBuilder.group({
          general_safety: [
            general_and_type_of_work && general_and_type_of_work.general_safety
              ? general_and_type_of_work.general_safety
              : 0,
          ],
          mobilization: [
            general_and_type_of_work && general_and_type_of_work.mobilization
              ? general_and_type_of_work.mobilization
              : 0,
          ],
          refrigeration: [
            general_and_type_of_work && general_and_type_of_work.refrigeration
              ? general_and_type_of_work.refrigeration
              : 0,
          ],
          general_pipe_installation: [
            general_and_type_of_work &&
              general_and_type_of_work.general_pipe_installation
              ? general_and_type_of_work.general_pipe_installation
              : 0,
          ],
          duct_installation: [
            general_and_type_of_work &&
              general_and_type_of_work.duct_installation
              ? general_and_type_of_work.duct_installation
              : 0,
          ],
          demolition: [
            general_and_type_of_work && general_and_type_of_work.demolition
              ? general_and_type_of_work.demolition
              : 0,
          ],
          insulation_duct_pipe: [
            general_and_type_of_work &&
              general_and_type_of_work.insulation_duct_pipe
              ? general_and_type_of_work.insulation_duct_pipe
              : 0,
          ],
          asbstos_lead_removal: [
            general_and_type_of_work &&
              general_and_type_of_work.asbstos_lead_removal
              ? general_and_type_of_work.asbstos_lead_removal
              : 0,
          ],
          demobilization: [
            general_and_type_of_work && general_and_type_of_work.demobilization
              ? general_and_type_of_work.demobilization
              : 0,
          ],
        }),
        safety_and_health_hazards: this.formBuilder.group({
          mobile_equipment: [
            safety_and_health_hazards &&
              safety_and_health_hazards.mobile_equipment
              ? safety_and_health_hazards.mobile_equipment
              : 0,
          ],
          man_personnel_lift: [
            safety_and_health_hazards &&
              safety_and_health_hazards.man_personnel_lift
              ? safety_and_health_hazards.man_personnel_lift
              : 0,
          ],
          forklift: [
            safety_and_health_hazards && safety_and_health_hazards.forklift
              ? safety_and_health_hazards.forklift
              : 0,
          ],
          a_frame_ladders: [
            safety_and_health_hazards &&
              safety_and_health_hazards.a_frame_ladders
              ? safety_and_health_hazards.a_frame_ladders
              : 0,
          ],
          scaffold: [
            safety_and_health_hazards && safety_and_health_hazards.scaffold
              ? safety_and_health_hazards.scaffold
              : 0,
          ],
          roof_access: [
            safety_and_health_hazards && safety_and_health_hazards.roof_access
              ? safety_and_health_hazards.roof_access
              : 0,
          ],
          hosting_rigging: [
            safety_and_health_hazards &&
              safety_and_health_hazards.hosting_rigging
              ? safety_and_health_hazards.hosting_rigging
              : 0,
          ],
          trench_and_excavation: [
            safety_and_health_hazards &&
              safety_and_health_hazards.trench_and_excavation
              ? safety_and_health_hazards.trench_and_excavation
              : 0,
          ],
          hot_work: [
            safety_and_health_hazards && safety_and_health_hazards.hot_work
              ? safety_and_health_hazards.hot_work
              : 0,
          ],
          energized_system: [
            safety_and_health_hazards &&
              safety_and_health_hazards.energized_system
              ? safety_and_health_hazards.energized_system
              : 0,
          ],
          high_noise: [
            safety_and_health_hazards && safety_and_health_hazards.high_noise
              ? safety_and_health_hazards.high_noise
              : 0,
          ],
          limited_access: [
            safety_and_health_hazards &&
              safety_and_health_hazards.limited_access
              ? safety_and_health_hazards.limited_access
              : 0,
          ],
          confined_space_access: [
            safety_and_health_hazards &&
              safety_and_health_hazards.confined_space_access
              ? safety_and_health_hazards.confined_space_access
              : 0,
          ],
          extreme_heat: [
            safety_and_health_hazards && safety_and_health_hazards.extreme_heat
              ? safety_and_health_hazards.extreme_heat
              : 0,
          ],
          // other: [
          //   safety_and_health_hazards && safety_and_health_hazards.other
          //     ? safety_and_health_hazards.other
          //     : 0,
          // ],
          exposure_to_traffic: [
            safety_and_health_hazards &&
              safety_and_health_hazards.exposure_to_traffic
              ? safety_and_health_hazards.exposure_to_traffic
              : 0,
          ],
          slilica_duct: [
            safety_and_health_hazards && safety_and_health_hazards.slilica_duct
              ? safety_and_health_hazards.slilica_duct
              : 0,
          ],
          compressed_gas: [
            safety_and_health_hazards &&
              safety_and_health_hazards.compressed_gas
              ? safety_and_health_hazards.compressed_gas
              : 0,
          ],
          creating_floor_roof_penetration: [
            safety_and_health_hazards &&
              safety_and_health_hazards.creating_floor_roof_penetration
              ? safety_and_health_hazards.creating_floor_roof_penetration
              : 0,
          ],
          // other1: [
          //   safety_and_health_hazards.other1
          //     ? safety_and_health_hazards.other1
          //     : 0,
          // ],
          other_list: this.formBuilder.array([this.addAdditionalOthersInfoRows(safety_and_health_hazards && safety_and_health_hazards.other_list ? safety_and_health_hazards.other_list : null)]),
          other_description: [safety_and_health_hazards.other_description],
          other_hazards: [safety_and_health_hazards.other_hazards],
          other_actions: [safety_and_health_hazards.other_actions],

          // other1: [
          //   safety_and_health_hazards && safety_and_health_hazards.other1
          //     ? safety_and_health_hazards.other1
          //     : 0,
          // ],
          // other1_description_of_work:
          //   safety_and_health_hazards &&
          //   safety_and_health_hazards.other1_description_of_work
          //     ? safety_and_health_hazards &&
          //       safety_and_health_hazards.other1_description_of_work
          //     : 'Other - ',
          // other1_hazards_associated_with_each_step:
          //   safety_and_health_hazards &&
          //   safety_and_health_hazards.other1_hazards_associated_with_each_step
          //     ? safety_and_health_hazards &&
          //       safety_and_health_hazards.other1_hazards_associated_with_each_step
          //     : '',
          // other1_required_action_to_eliminate_hazard:
          //   safety_and_health_hazards &&
          //   safety_and_health_hazards.other1_required_action_to_eliminate_hazard
          //     ? safety_and_health_hazards &&
          //       safety_and_health_hazards.other1_required_action_to_eliminate_hazard
          //     : '',
        }),
        work_permits: this.formBuilder.group({
          mobile_equipment: [
            work_permits && work_permits.mobile_equipment
              ? work_permits.mobile_equipment
              : 0,
          ],
          mobile_equipment_recieved: [
            work_permits && work_permits.mobile_equipment_recieved
              ? work_permits.mobile_equipment_recieved
              : 0,
          ],
          mobile_equipment_file: [
            work_permits && work_permits.mobile_equipment_file
              ? work_permits.mobile_equipment_file
              : '',
          ],
          man_personnel_lift: [
            work_permits && work_permits.man_personnel_lift
              ? work_permits.man_personnel_lift
              : 0,
          ],
          man_personnel_lift_recieved: [
            work_permits && work_permits.man_personnel_lift_recieved
              ? work_permits.man_personnel_lift_recieved
              : 0,
          ],
          man_personnel_lift_file: [
            work_permits && work_permits.man_personnel_lift_file
              ? work_permits.man_personnel_lift_file
              : '',
          ],
          forklift: [
            work_permits && work_permits.forklift ? work_permits.forklift : 0,
          ],
          forklift_recieved: [
            work_permits && work_permits.forklift_recieved
              ? work_permits.forklift_recieved
              : 0,
          ],
          forklift_file: [
            work_permits && work_permits.forklift_file
              ? work_permits.forklift_file
              : '',
          ],
          high_noise: [
            work_permits && work_permits.high_noise
              ? work_permits.high_noise
              : 0,
          ],
          high_noise_recieved: [
            work_permits && work_permits.high_noise_recieved
              ? work_permits.high_noise_recieved
              : 0,
          ],
          high_noise_file: [
            work_permits && work_permits.high_noise_file
              ? work_permits.high_noise_file
              : '',
          ],
          limited_access: [
            work_permits && work_permits.limited_access
              ? work_permits.limited_access
              : 0,
          ],
          limited_access_recieved: [
            work_permits && work_permits.limited_access_recieved
              ? work_permits.limited_access_recieved
              : 0,
          ],
          limited_access_file: [
            work_permits && work_permits.limited_access_file
              ? work_permits.limited_access_file
              : '',
          ],
          confined_space_access: [
            work_permits && work_permits.confined_space_access
              ? work_permits.confined_space_access
              : 0,
          ],
          confined_space_access_recieved: [
            work_permits && work_permits.confined_space_access_recieved
              ? work_permits.confined_space_access_recieved
              : 0,
          ],
          confined_space_access_file: [
            work_permits && work_permits.confined_space_access_file
              ? work_permits.confined_space_access_file
              : '',
          ],
        }),
        id: [data.id],
        employee_supervisor: [data.employee_supervisor],
        employee_date: [moment(data.employee_date).format('YYYY-MM-DD')],
        employee_signature: [data.employee_signature],
        permits_have_been_obtained: [data.permits_have_been_obtained],
        mobile_equipment_inspections_performed: [
          data.mobile_equipment_inspections_performed,
        ],
        preinspections_of_powered_tools_and_equipment_performed: [
          data.preinspections_of_powered_tools_and_equipment_performed,
        ],
        mobile_equipment_operator_cards_present: [
          data.mobile_equipment_operator_cards_present,
        ],
        is_archived: [data.is_archived],
        employee_signature_name: '',
        employee_signature_date: '',
        employee_signature_electronic: '',
      });

      if (general_and_type_of_work.general_safety == 1) {
        this.isGeneralSafety = true;
      }
      if (general_and_type_of_work.mobilization == 1) {
        this.isMobilization = true;
      }
      if (general_and_type_of_work.demobilization == 1) {
        this.isDemobilization = true;
      }
      if (general_and_type_of_work.refrigeration == 1) {
        this.isRefrigeration = true;
      }
      if (general_and_type_of_work.general_pipe_installation == 1) {
        this.isInstallationOfPiping = true;
      }
      if (general_and_type_of_work.duct_installation == 1) {
        this.isInstallationOfDuctwork = true;
      }
      if (general_and_type_of_work.demolition == 1) {
        this.isDemolition = true;
      }
      if (general_and_type_of_work.insulation_duct_pipe == 1) {
        this.isInsulationOfPiping = true;
      }
      if (general_and_type_of_work.asbstos_lead_removal == 1) {
        this.isAsbestos = true;
      }

      //--------------------------------------------------------------

      if (safety_and_health_hazards.mobile_equipment == 1) {
        this.isMobileEquipment = true;
      }

      if (safety_and_health_hazards.man_personnel_lift == 1) {
        this.isManPersonnelLift = true;
      }

      if (safety_and_health_hazards.forklift == 1) {
        this.isForklift = true;
      }

      if (safety_and_health_hazards.a_frame_ladders == 1) {
        this.isAFrameLadders = true;
      }

      if (safety_and_health_hazards.scaffold == 1) {
        this.isScaffold = true;
      }

      if (safety_and_health_hazards.roof_access == 1) {
        this.isRoofAccess = true;
      }

      if (safety_and_health_hazards.hosting_rigging == 1) {
        this.isHostingRigging = true;
      }

      if (safety_and_health_hazards.trench_and_excavation == 1) {
        this.isTrenchAndEvacuation = true;
      }

      if (safety_and_health_hazards.hot_work == 1) {
        this.isHotWork = true;
      }

      if (safety_and_health_hazards.energized_system == 1) {
        this.isEnergizedSystem = true;
      }

      if (safety_and_health_hazards.high_noise == 1) {
        this.isHighNoise = true;
      }

      if (safety_and_health_hazards.limited_access == 1) {
        this.isLimitedAccess = true;
      }

      if (safety_and_health_hazards.confined_space_access == 1) {
        this.isConfinedSpaceAccess = true;
      }

      if (safety_and_health_hazards.extreme_heat == 1) {
        this.isExtremeHeat = true;
      }

      if (safety_and_health_hazards.exposure_to_traffic == 1) {
        this.isExposureToTraffic = true;
      }

      if (safety_and_health_hazards.slilica_duct == 1) {
        this.isSilicaDuct = true;
      }

      if (safety_and_health_hazards.compressed_gas == 1) {
        this.isCompressedGas = true;
      }

      if (safety_and_health_hazards.creating_floor_roof_penetration == 1) {
        this.isCreatingFloorRoofPenetration = true;
      }

      if (safety_and_health_hazards.other_list) {
        this.enableOtherInput = true;
      } else {
        this.enableOtherInput = false;
      }

      //--------------------------------------------------------------

      this.supervisorSignatureSrc = data.employee_signature;
    } else {
      //Generate form group for add roles and permission
      this.jobForm = this.formBuilder.group({
        project_name: '',
        job_supervisor: '',
        date_submitted: '',
        general_and_type_of_work: this.formBuilder.group({
          general_safety: [1],
          mobilization: [1],
          refrigeration: [],
          general_pipe_installation: [],
          duct_installation: [0],
          demolition: [0],
          insulation_duct_pipe: [0],
          asbstos_lead_removal: [0],
          demobilization: [1],
        }),
        safety_and_health_hazards: this.formBuilder.group({
          mobile_equipment: [0],
          man_personnel_lift: [0],
          forklift: [0],
          a_frame_ladders: [0],
          scaffold: [0],
          roof_access: [0],
          hosting_rigging: [0],
          trench_and_excavation: [0],
          hot_work: [0],
          energized_system: [0],
          high_noise: [0],
          limited_access: [0],
          confined_space_access: [0],
          extreme_heat: [0],
          exposure_to_traffic: [0],
          slilica_duct: [0],
          compressed_gas: [0],
          creating_floor_roof_penetration: [0],
          // other1: [0],
          other_list: this.formBuilder.array([this.addAdditionalOthersInfoRows()]),
          other: this.formBuilder.group({
            other_description: [''],
            other_hazards: [''],
            other_actions: [''],
          }),
        }),
        work_permits: this.formBuilder.group({
          mobile_equipment: [0],
          mobile_equipment_recieved: [0],
          mobile_equipment_file: [],
          man_personnel_lift: [0],
          man_personnel_lift_recieved: [0],
          man_personnel_lift_file: [],
          forklift: [0],
          forklift_recieved: [0],
          forklift_file: [],
          high_noise: [0],
          high_noise_recieved: [0],
          high_noise_file: [],
          limited_access: [0],
          limited_access_recieved: [0],
          limited_access_file: [],
          confined_space_access: [0],
          confined_space_access_recieved: [0],
          confined_space_access_file: [],
        }),
        id: [''],
        employee_supervisor: [''],
        employee_date: [''],
        employee_signature: [''],
        permits_have_been_obtained: [''],
        mobile_equipment_inspections_performed: [''],
        preinspections_of_powered_tools_and_equipment_performed: [''],
        mobile_equipment_operator_cards_present: [''],
        is_archived: [0],
      });
    }
  }

  // Add additional data in permit type form
  addAdditionalOthersInfoRows(data = null) {
    /* Add new rows to existing dates */
    let group;
    group = this.formBuilder.group({
      indi_others: this.formBuilder.array([])
    });

    //If data exist, set data in permit type form
    if (data != null) {
      data.forEach((element, index) => {
        this.addAdditionalOthersInfoSameEditTime(group.controls.indi_others, element);
      });
    } else {
      this.addAdditionalOthersInfoSameEditTime(group.controls.indi_others, data);
    }

    return group;
  }

  // Add form parameter in group
  addAdditionalOthersInfoSameEditTime(others: any, data = null) {
    /* Add new requests to existing dates */ //debugger

    let group;
    if (data != null) {
      //If data exist, set data in fields
      group = this.formBuilder.group({
        value: [data.value ? data.value : 0],
        description_of_work: data.description_of_work,
        hazards_associated_with_each_step: data.hazards_associated_with_each_step,
        required_action_to_eliminate_hazard: data.required_action_to_eliminate_hazard
      });

      others.push(group);
    } else {

      group = this.formBuilder.group({
        value: 0,
        description_of_work: '',
        hazards_associated_with_each_step: '',
        required_action_to_eliminate_hazard: ''
      });

      others.push(group);
    }

  }

  //Set 0/1 on checkbox checked false/true
  setValue(event, formGroupName, key, index = null) {
    if (formGroupName != '') {
      if (formGroupName == "safety_and_health_hazards") {
        if (key == "others") {
          this.selectedOtherIndex = index;
          if (event == 1) {
            this.description_of_work = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['description_of_work'].value;
            this.hazards_associated_with_each_step = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['hazards_associated_with_each_step'].value;
            this.required_action_to_eliminate_hazard = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['required_action_to_eliminate_hazard'].value;
            this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['value'].setValue(1);
            this.enableOtherInput = true;
          } else {
            this.description_of_work = '';
            this.hazards_associated_with_each_step = '';
            this.required_action_to_eliminate_hazard = '';
            this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['value'].setValue(0);
            this.submitOther();
            // this.enableOtherInput = false;
            // this.jobForm
            //   .get(formGroupName)
            // ['controls']['other1_description_of_work'].setValue('Other - ');
            // this.jobForm
            //   .get(formGroupName)
            // ['controls']['other1_hazards_associated_with_each_step'].setValue('');
            // this.jobForm
            //   .get(formGroupName)
            // ['controls']['other1_required_action_to_eliminate_hazard'].setValue('');

          }
        }
      }
      if (key != '' && index == null) {
        this.jobForm
          .get(formGroupName)
        ['controls'][key].setValue(event == 1 ? 1 : 0);
      }
    } else {
      if (key != '') {
        this.jobForm.get(key).setValue(event == 1 ? 1 : event == 2 ? 2 : 0);
      }
    }
  }

  onSubmit(form) {
    if (
      this.jobForm.get('work_permits')['controls']['mobile_equipment_file']
        .value
    ) {
      if (
        this.jobForm.get('work_permits')['controls']['mobile_equipment_file']
          .value.name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['mobile_equipment_file']
            .value.name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls']['mobile_equipment_file']
            .value
        );
        form.value.work_permits.mobile_equipment_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (
      this.jobForm.get('work_permits')['controls']['man_personnel_lift_file']
        .value
    ) {
      if (
        this.jobForm.get('work_permits')['controls']['man_personnel_lift_file']
          .value.name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
            'man_personnel_lift_file'
          ].value.name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls'][
            'man_personnel_lift_file'
          ].value
        );
        form.value.work_permits.man_personnel_lift_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (this.jobForm.get('work_permits')['controls']['forklift_file'].value) {
      if (
        this.jobForm.get('work_permits')['controls']['forklift_file'].value.name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['forklift_file'].value
            .name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls']['forklift_file'].value
        );
        form.value.work_permits.forklift_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (this.jobForm.get('work_permits')['controls']['high_noise_file'].value) {
      if (
        this.jobForm.get('work_permits')['controls']['high_noise_file'].value
          .name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['high_noise_file'].value
            .name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls']['high_noise_file'].value
        );
        form.value.work_permits.high_noise_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (
      this.jobForm.get('work_permits')['controls']['limited_access_file'].value
    ) {
      if (
        this.jobForm.get('work_permits')['controls']['limited_access_file']
          .value.name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['limited_access_file']
            .value.name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls']['limited_access_file']
            .value
        );
        form.value.work_permits.limited_access_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (
      this.jobForm.get('work_permits')['controls']['confined_space_access_file']
        .value
    ) {
      if (
        this.jobForm.get('work_permits')['controls'][
          'confined_space_access_file'
        ].value.name
      ) {
        var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
            'confined_space_access_file'
          ].value.name
          }`;
        const filePath = `uploads/pts/permits/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          `uploads/pts/permits/${n}`,
          this.jobForm.get('work_permits')['controls'][
            'confined_space_access_file'
          ].value
        );
        form.value.work_permits.confined_space_access_file = n;
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((url) => {
                if (url) {
                }
              });
            })
          )
          .subscribe((url) => {
            if (url) {
              console.log(url);
            }
          });
      }
    }
    if (form.invalid) {
    } else {
      //Check for if role has id or not
      if (
        form.value.id != '' &&
        form.value.id != null &&
        form.value.id != undefined
      ) {
        if (
          form.value.employee_signature_name != '' &&
          form.value.employee_signature_name != null &&
          form.value.employee_signature_name != undefined &&
          form.value.employee_signature_date != '' &&
          form.value.employee_signature_date != null &&
          form.value.employee_signature_date != undefined
        ) {
          let newEmployeeSignature = {
            job_id: this.jobId,
            name: form.value.employee_signature_name,
            signature_date: form.value.employee_signature_date,
            electronic_signature: this.localStorage.getItem('savedSignature'),
          };

          this.employeeSignatureService
            .createEmployeeSignature(newEmployeeSignature)
            .subscribe(
              (res) => {
                if (res) {
                  if (res.data) {
                    this.getEmployeeSignature();
                  }
                }
              },
              (error) => console.log(error)
            );
        }

        if (this.jobForm.get('employee_signature_name')) {
          this.jobForm.get('employee_signature_name').setValue('');
        }
        if (this.jobForm.get('employee_signature_date')) {
          this.jobForm.get('employee_signature_date').setValue('');
        }
        if (this.jobForm.get('employee_signature_electronic')) {
          this.jobForm.get('employee_signature_electronic').setValue('');
        }
        form.value.safety_and_health_hazards.other_list = this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others'].value;
        delete form.value.employee_signature_name;
        delete form.value.employee_signature_date;
        delete form.value.employee_signature_electronic;

        //Update role and permission if id exist
        this.jobsService.updateJob(form.value.id, form.value).subscribe(
          (res) => this.onJobUpdateSuccess(res),
          (error) => this.onJobUpdateError(error)
        );
      } else {
        //Add new role and permission if id does not exist
        delete form.value.id;
        this.jobsService.createJob(form.value).subscribe(
          (res) => this.onJobUpdateSuccess(res),
          (error) => this.onJobUpdateError(error)
        );
      }
    }
  }

  //On success of add/update permission api
  onJobUpdateSuccess(result) {
    if (result) {
      this.getJob();
      this.getEmployeeSignature();
      this.toastService.showToast('Job updated successfully', 'success');
    }
  }

  //On error of add/update permission api
  onJobUpdateError(error) {
    this.toastService.showToast('Job Error', 'error');
  }

  onFileSelect(event, type) {
    if (event.target.files.length > 0) {
      let flag = 1;
      const file = event.target.files[0];

      if (event.target.files.length === 0) return;

      setTimeout(() => {
        this.jobForm.get('work_permits')['controls'][type].setValue(file);
        var reader = new FileReader();
        if (type == 'mobile_equipment_file') {
          this.mobile_equipment_file_name = event.target.files[0].name;
        }
        if (type == 'man_personnel_lift_file') {
          this.man_personnel_lift_file_name = event.target.files[0].name;
        }
        if (type == 'forklift_file') {
          this.forklift_file_name = event.target.files[0].name;
        }
        if (type == 'high_noise_file') {
          this.high_noise_file_name = event.target.files[0].name;
        }
        if (type == 'limited_access_file') {
          this.limited_access_file_name = event.target.files[0].name;
        }
        if (type == 'confined_space_access_file') {
          this.confined_space_access_file_name = event.target.files[0].name;
        }

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => { };
      }, 500);
    }
  }

  removeFile(type) {
    this.jobForm.get('work_permits')['controls'][type].setValue('');
    if (type == 'mobile_equipment_file') {
      this.mobile_equipment_file_name = '';
    }
    if (type == 'man_personnel_lift_file') {
      this.man_personnel_lift_file_name = '';
    }
    if (type == 'forklift_file') {
      this.forklift_file_name = '';
    }
    if (type == 'high_noise_file') {
      this.high_noise_file_name = '';
    }
    if (type == 'limited_access_file') {
      this.limited_access_file_name = '';
    }
    if (type == 'confined_space_access_file') {
      this.confined_space_access_file_name = '';
    }
  }

  checkIfArchived(field) {
    return this.jobForm.get(field).value;
  }

  updateFormArchive(archive) {
    if (archive == 0) {
      this.jobsService.archiveJob(this.jobId).subscribe(
        (res) => this.onArchiveJobSuccess(res),
        (error) => this.onArchiveJobError(error)
      );
    } else if (archive == 1) {
      this.jobsService.unArchiveJob(this.jobId).subscribe(
        (res) => this.onArchiveJobSuccess(res),
        (error) => this.onArchiveJobError(error)
      );
    }
  }

  onArchiveJobSuccess(result) {
    if (result) {
      if (result.data) {
        this.getJob();
        this.getEmployeeSignature();
        this.toastService.showToast(result.data.message, 'success');
      }
    }
  }

  onArchiveJobError(error) { }

  getEmployeeSignature() {
    this.employeeSignatureService
      .getEmployeeSignature(this.employeeSearchInfo)
      .subscribe(
        (res) => this.onEmployeeSignatureGetSuccess(res),
        (error) => this.onEmployeeSignatureGetError(error)
      );
  }

  //On success of add/get permission api
  onEmployeeSignatureGetSuccess(result) {
    if (result) {
      if (result.data) {
        this.clearDatatable();
        this.employeeSignatureData = result.data;
        this.localStorage.setItem('savedSignature', '');
        this.signaturePad.clear();
        this.drawDatatable();
      }
    }
  }

  //On error of add/get permission api
  onEmployeeSignatureGetError(error) {
    this.toastService.showToast('Job Error', 'error');
  }

  addEmployeeSignature(newEmployeeSignature) {
    this.employeeSignatureService
      .createEmployeeSignature(newEmployeeSignature)
      .subscribe(
        (res) => this.onAddEmployeeSignatureGetSuccess(res),
        (error) => this.onAddEmployeeSignatureGetError(error)
      );
  }

  //On success of add/get permission api
  onAddEmployeeSignatureGetSuccess(result) {
    if (result) {
      if (result.data) {
        this.getEmployeeSignature();
      }
    }
  }

  //On error of add/get permission api
  onAddEmployeeSignatureGetError(error) {
    this.toastService.showToast('Job Error', 'error');
  }

  setDate(type) {
    if (type == 'start_date') {
      document
        .getElementById('date-to')
        .setAttribute(
          'min',
          (<HTMLInputElement>document.getElementById('date-from')).value
        );
    } else if (type == 'end_date') {
      document
        .getElementById('date-from')
        .setAttribute(
          'max',
          (<HTMLInputElement>document.getElementById('date-to')).value
        );
    }

    this.employeeSearchInfo = {
      job_id: this.jobId,
      start_date: this.start_date,
      end_date: this.end_date,
    };
    this.getEmployeeSignature();
  }

  public drawBegin(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png');
    this.localStorage.setItem('savedSignature', this.signature);
  }

  clearSignature() {
    //var signaturePad = new SignaturePad(this.signaturePad);
    this.localStorage.setItem('savedSignature', '');
    this.signaturePad.clear();
  }

  showGeneralPtp() {
    var generalPtp = document.getElementById('general-ptp');

    generalPtp.classList.toggle('hide');
  }

  showSafetyPtp() {
    const safetyPtp = document.getElementById('safety-ptp');
    safetyPtp.classList.toggle('hide');
    this.clickExpand();
  }

  sendEmail(email) {
    const mailButton = <HTMLInputElement>document.querySelector('#submit');
    mailButton.disabled = true;
    setTimeout(() => {
      mailButton.disabled = false;
    }, 3000);
    if (email != '' && email != null && email != undefined) {
      this.jobsService
        .sendEmail(this.jobId, email, this.start_date, this.end_date)
        .subscribe(
          (res) => {
            this.toastService.showToast(res.data.message, res.data.status);
            this.EmailNotificationText =
              "<i class='fa fa-info-circle'></i>" + res.data.message;
          },
          (error) => {
            console.log('error');
          }
        );
    }
  }

  buttonTikmeout() {
    console.log('test');
  }

  showOther(e) {
    this.otherVis = e;
  }

  submitOther() {
    if (Number(this.selectedOtherIndex) > -1) {
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['description_of_work'].setValue(this.description_of_work);
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['hazards_associated_with_each_step'].setValue(this.hazards_associated_with_each_step);
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['required_action_to_eliminate_hazard'].setValue(this.required_action_to_eliminate_hazard);
      this.description_of_work = '';
      this.hazards_associated_with_each_step = '';
      this.required_action_to_eliminate_hazard = '';
    }
    this.closeOtherModel.nativeElement.click();
  }
  clickExpand() {
    const targets = document.querySelectorAll('.ptpText');
    targets.forEach(element => {
      this.autoExpand(element);
    });
  }


  autoExpand(e) {

    console.log(e.tagName);

    if (e.tagName === 'TEXTAREA') {
      const field = e;

          // Reset field height
      console.log('area');
      field.style.height = 'inherit';

      // Get the computed styles for the element
      const computed = window.getComputedStyle(field);

      // Calculate the height
      const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                  + parseInt(computed.getPropertyValue('padding-top'), 10)
                  + field.scrollHeight
                  + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                  + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      field.style.height = height + 5 + 'px';

    } else {
      const field = e.target;
          // Reset field height
      console.log('event');
      field.style.height = 'inherit';

      // Get the computed styles for the element
      const computed = window.getComputedStyle(field);

      // Calculate the height
      const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                  + parseInt(computed.getPropertyValue('padding-top'), 10)
                  + field.scrollHeight
                  + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                  + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      field.style.height = height + 5 + 'px';

    }
  }
}
