import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpService,
  HttpRequestModel,
} from 'src/app/service/httpservice/http.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  HttpRequestModel: HttpRequestModel = new HttpRequestModel();

  constructor(private httpService: HttpService) { }

  getJobs(searchInfo = null): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = searchInfo;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  getJobById(id): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/' + id;
    this.HttpRequestModel.method = 'GET';

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to create job
  createJob(jobInfo): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/add';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = jobInfo;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to update job
  updateJob(id, jobInfo): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/update/' + id;
    this.HttpRequestModel.method = 'PUT';
    this.HttpRequestModel.body = jobInfo;

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to archive job
  archiveJob(id): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/archive/' + id;
    this.HttpRequestModel.method = 'PUT';
    this.HttpRequestModel.body = '';

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  //Request to unarchive job
  unArchiveJob(id): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/unarchive/' + id;
    this.HttpRequestModel.method = 'PUT';
    this.HttpRequestModel.body = '';

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }

  sendEmail(id, email, startdate, enddate): Observable<any> {
    this.HttpRequestModel.reset();
    this.HttpRequestModel.url = 'jobs/sendmail';
    this.HttpRequestModel.method = 'POST';
    this.HttpRequestModel.body = {
      email: email,
      id: id,
      startdate: startdate,
      enddate: enddate
    };

    return this.httpService
      .init(this.HttpRequestModel)
      .pipe(map((response) => response));
  }
}
