import { Component, OnInit } from '@angular/core';
import { AccessCodeGeneratorService } from 'src/app/service/access-code-generator/access-code-generator.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-access-code-generator',
  templateUrl: './access-code-generator.component.html',
  styleUrls: ['./access-code-generator.component.scss']
})
export class AccessCodeGeneratorComponent implements OnInit {
  generate = false;
  lastGeneratedCodeDetails = [];
  newCode = "";
  notValid = false;
  notValidPattrn = false;
  constructor(public accessCodeGeneratorService: AccessCodeGeneratorService) {
    moment.tz.setDefault('America/New_York');
  }

  ngOnInit(): void {
    this.getLatestAccessCodeDetails();
  }

  getLatestAccessCodeDetails() {
    this.accessCodeGeneratorService.getLatestCode().subscribe(
      (res) => this.onGetAccessCodeSuccess(res),
      (error) => this.onGetAccessCodeError(error)
    );
  }

  onGetAccessCodeSuccess(result) {
    if (result) {
      if (result.data) {
        this.lastGeneratedCodeDetails = result.data[0];
      }
    }
  }

  onGetAccessCodeError(error) {
  }

  generateCode() {
    // this.generate = true;
    this.randomString();
    this.submitCode();
  }

  submitCode() {
    if (this.newCode) {
      var letterNumber = /^[0-9a-zA-Z]+$/;
      if (this.newCode.match(letterNumber)) {
        this.notValidPattrn = false;
        let newAccessCode = {
          code: this.newCode,
          active: 1,
          created_at: moment().format('YYYY-MM-DD hh:mm:ss')
        }
        this.accessCodeGeneratorService.createAccessCode(newAccessCode).subscribe(
          (res) => this.onAddAccessCodeSuccess(res),
          (err) => this.onAddAccessCodeError(err),
        )
      } else {
        this.notValidPattrn = true;
      }
      this.notValid = false;
    } else {
      this.notValid = true;
    }
  }

  onAddAccessCodeSuccess(result) {
    if (result) {
      if (result.data) {
        this.generate = false;
        this.getLatestAccessCodeDetails();
      }
    }
  }

  onAddAccessCodeError(error) {

  }

  getFormattedDate(date) {
    if (date) {
      return moment(date).format('MM/DD/YYYY');
    } else {
      return "";
    }
  }

  randomString(length = 6, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    this.newCode = result;
  }
}
