// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // End point url
  apiUrl: 'https://pts-contracting-api.spark-staging.dev/',
  authToken: 'local',
  // Web app's Firebase configuration
  config: {
    apiKey: 'AIzaSyAUfHhU2-Bf9IynhIQXao7lmro7tpRTTKk',
    authDomain: 'fir-1-5ad56.firebaseapp.com',
    databaseURL: 'https://fir-1-5ad56.firebaseio.com',
    projectId: 'fir-1-5ad56',
    storageBucket: 'fir-1-5ad56.appspot.com',
    messagingSenderId: '152448047433',
    appId: '1:152448047433:web:16aa45115db83c6ee22298',
    measurementId: 'G-TJZJG7V888',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
