<div class="pts-contracting acg-main">
    <div class="container">
        <div class="logo-wrap">
            <a routerLink="/" class="logo"><img src="../../../assets/images/logo.jpg" title="PTS Contracting"
                    alt="PTS Contracting" /></a>
        </div>
        <div class="acg-details">
            <div class="new-info">
                <div><input type="text" [(ngModel)]="newCode" class="input-text" id="accessCode"
                        placeholder="Enter Access Code Here" maxlength="6" minlength="6">
                    <span class="error" *ngIf="notValid">Please enter access code</span>
                    <span class="error" *ngIf="notCorrect">Incorrect access code</span>
                </div>
            </div>
            <div class="text-center">
                <button type="button" class="submitBtn" (click)="submitCode()">Submit</button>
            </div>
        </div>
    </div>
</div>