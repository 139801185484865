<div class="pts-contracting">
  <div class="container">
    <header>
      <div class="logo-wrap">
        <a routerLink="/" class="logo"
          ><img
            src="../../../assets/images/logo.jpg"
            title="PTS Contracting"
            alt="PTS Contracting"
        /></a>
      </div>
      <div class="text-wrap">
        <p>PTS Contracting Safety Precaution and Pre-Job Brief Requirements</p>
        <span>Job Safety Analysis (JSA)</span>
      </div>
    </header>

    <div class="create-jsa">
      <a class="anchor-link-remove" routerLink="/jobs">
        <div class="heading">Create New JSA</div>
      </a>

      <div class="search-container">
        <input
          class="input-text"
          type="text"
          #search
          placeholder="Search JSA Forms"
          [(ngModel)]="searchInfo.search"
          title="Search JSA Forms"
          (keyup)="searchInTable(search.value, 'search')"
        />
        <button
          type="submit"
          id="btn-search"
          title="Search"
          class="action primary"
        ></button>
      </div>
    </div>

    <div class="create-history">
      <h3>JSA Form History</h3>
      <form class="form">
        <!-- <div class="fieldset">
                    <div class="field ">
                        <label class="label" for="name">Project:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Project:" value="" class="input-text"
                                type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Job Supervisor/Foreman:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Job Supervisor/Foreman:" value=""
                                class="input-text" type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Date:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Date:" value="" class="input-text" type="date"
                                data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>
                    <div class="field">
                        <a routerLink="/updatejobs" class="view-btn">view JSA</a>
                    </div>
                </div>
                <div class="fieldset">
                    <div class="field ">
                        <label class="label" for="name">Project:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Project:" value="" class="input-text"
                                type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Job Supervisor/Foreman:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Job Supervisor/Foreman:" value=""
                                class="input-text" type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Date:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Date:" value="" class="input-text" type="date"
                                data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>
                    <div class="field">
                        <a routerLink="/updatejobs" class="view-btn">view JSA</a>
                    </div>
                </div>
                <div class="fieldset">
                    <div class="field ">
                        <label class="label" for="name">Project:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Project:" value="" class="input-text"
                                type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Job Supervisor/Foreman:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Job Supervisor/Foreman:" value=""
                                class="input-text" type="text" data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="name">Date:</label>
                        <div class="control">
                            <input name="firstname" id="firstname" title="Date:" value="" class="input-text" type="date"
                                data-validate="{required:true}" aria-required="true">
                        </div>
                    </div>
                    <div class="field">
                        <a routerLink="/updatejobs" class="view-btn">view JSA</a>
                    </div>
                </div> -->
        <div class="wrap">
          <table class="data table recent" id="my-orders-table">
            <thead>
              <tr>
                <th scope="col" class="col project">Project:</th>
                <th scope="col" class="col supervisor">
                  Job Supervisor/Foreman:
                </th>
                <th scope="col" class="col date">Date:</th>
                <th scope="col" class="col view"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of jobsData">
                <td data-th="Project:" class="col project">
                  {{ item.project_name }}
                </td>
                <td data-th="Job Supervisor/Foreman:" class="col supervisor">
                  {{ item.job_supervisor }}
                </td>
                <td data-th="Date :" class="col date">
                  {{ item.date_submitted }}
                </td>
                <td data-th="" class="col view">
                  <a
                    (click)="goToJobDetails(item.id)"
                    class="view-btn link-pointer"
                    >view JSA</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
