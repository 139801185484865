<div class="pts-contracting jsa-detail">
  <div class="container">
    <div class="create-jsa">
      <div class="heading">Create New JSA</div>
    </div>

    <header>
      <div class="text-wrap">
        <p>PTS Contracting Safety Precaution and Pre-Job Brief Requirements</p>
        <span>Job Safety Analysis (JSA)</span>
      </div>
      <div class="logo-wrap">
        <a routerLink="/" class="logo"><img src="../../../assets/images/logo.jpg" title="PTS Contracting"
            alt="PTS Contracting" /></a>
      </div>
    </header>
    <form [formGroup]="jobForm" (ngSubmit)="onSubmit(jobForm)">
      <div class="create-history">
        <h3>Project Details</h3>
        <div class="wrap">
          <div class="fieldset">
            <div class="field">
              <label class="label" for="name">Project:</label>
              <div class="control">
                <input id="project_name" title="Project:" value="" class="input-text" type="text"
                  data-validate="{required:true}" aria-required="true" formControlName="project_name" />
                <span class="error" *ngIf="
                    jobForm.get('project_name').hasError('required') &&
                    jobForm.get('project_name').touched
                  ">
                  Please provide project name
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Project Start Date:</label>
              <div class="control">
                <input id="firstname" title="Date:" value="" class="input-text" type="date"
                  data-validate="{required:true}" aria-required="true" formControlName="date_submitted" />
                <span class="error" *ngIf="
                    jobForm.get('date_submitted').hasError('required') &&
                    jobForm.get('date_submitted').touched
                  ">
                  Please provide date submitted
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Job Supervisor/Foreman:</label>
              <div class="control">
                <input id="job_supervisor" title="Job Supervisor/Foreman:" value="" class="input-text" type="text"
                  data-validate="{required:true}" aria-required="true" formControlName="job_supervisor" />
                <span class="error" *ngIf="
                    jobForm.get('job_supervisor').hasError('required') &&
                    jobForm.get('job_supervisor').touched
                  ">
                  Please provide job supervisor/foreman
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="type-wotk" formGroupName="general_and_type_of_work">
        <h2>General and Type of Work</h2>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <label for="general_safety" class="label">
                <p>General Safety</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="generalsafetyyes" [value]="1" checked title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'general_safety'
                      )
                    " formControlName="general_safety" />
                  <label for="generalsafetyyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="generalsafetyno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'general_safety'
                      )
                    " formControlName="general_safety" />
                  <label for="generalsafetyno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="mobilization" class="label">
                <p>Mobilization</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobilizationyes" checked [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'mobilization')
                    " formControlName="mobilization" />
                  <label for="mobilizationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobilizationno" [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'mobilization')
                    " formControlName="mobilization" />
                  <label for="mobilizationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="refrigeration" class="label">
                <p>Refrigeration</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="refrigerationyes" #refrigerationyes [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'refrigeration')
                    " formControlName="refrigeration" />
                  <label for="refrigerationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="refrigerationno" #refrigerationno [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'refrigeration')
                    " formControlName="refrigeration" />
                  <label for="refrigerationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="general_pipe_installation" class="label">
                <p>Pipe Installation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="general_pipe_installationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'general_pipe_installation'
                      )
                    " formControlName="general_pipe_installation" />
                  <label for="general_pipe_installationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="general_pipe_installationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'general_pipe_installation'
                      )
                    " formControlName="general_pipe_installation" />
                  <label for="general_pipe_installationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="duct_installation" class="label">
                <p>Duct Installation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="duct_installationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'duct_installation'
                      )
                    " formControlName="duct_installation" />
                  <label for="duct_installationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="duct_installationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'duct_installation'
                      )
                    " formControlName="duct_installation" />
                  <label for="duct_installationno">No</label>
                </span>
              </div>
            </div>
          </div>
          <div class="work-wrap">
            <div class="work-inner">
              <label for="demolition" class="label">
                <p>Demolition</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="demolitionyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'demolition')
                    " formControlName="demolition" />
                  <label for="demolitionyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="demolitionyesno" [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'demolition')
                    " formControlName="demolition" />
                  <label for="demolitionyesno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="insulationductpipe" class="label">
                <p>Insulation Duct/Pipe</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="insulationductpipeyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'insulation_duct_pipe'
                      )
                    " formControlName="insulation_duct_pipe" />
                  <label for="insulationductpipeyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="insulationductpipeno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'insulation_duct_pipe'
                      )
                    " formControlName="insulation_duct_pipe" />
                  <label for="insulationductpipeno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="asbestosleadremoval" class="label">
                <p>Asbestos Lead Removal</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="asbestosleadremovalyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'asbstos_lead_removal'
                      )
                    " formControlName="asbstos_lead_removal" />
                  <label for="asbestosleadremovalyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="asbestosleadremovalno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'asbstos_lead_removal'
                      )
                    " formControlName="asbstos_lead_removal" />
                  <label for="asbestosleadremovalno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="demobilization" class="label">
                <p>Demobilization</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="demobilizationyes" checked [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'demobilization'
                      )
                    " formControlName="demobilization" />
                  <label for="demobilizationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="demobilizationyesno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'demobilization'
                      )
                    " formControlName="demobilization" />
                  <label for="demobilizationyesno">No</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="type-wotk health-hazards" formGroupName="safety_and_health_hazards">
        <h2>Safety and Health Hazards</h2>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <label for="mobileequipment" class="label">
                <p>Mobile Equipment</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobileequipmentyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'mobile_equipment'
                      )
                    " formControlName="mobile_equipment" />
                  <label for="mobileequipmentyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'mobile_equipment'
                      )
                    " formControlName="mobile_equipment" />
                  <label for="mobileequipmentno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="manpersonnellift" class="label">
                <p>Man/Personnel Lift</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="manpersonnelliftyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'man_personnel_lift'
                      )
                    " formControlName="man_personnel_lift" />
                  <label for="manpersonnelliftyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="manpersonnelliftno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'man_personnel_lift'
                      )
                    " formControlName="man_personnel_lift" />
                  <label for="manpersonnelliftno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="forklift" class="label">
                <p>Forklift</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'forklift')
                    " formControlName="forklift" />
                  <label for="forkliftyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'forklift')
                    " formControlName="forklift" />
                  <label for="forkliftno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="aframeladders" class="label">
                <p>A-Frame Ladders</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="aframeladdersyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'a_frame_ladders'
                      )
                    " formControlName="a_frame_ladders" />
                  <label for="aframeladdersyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="aframeladdersno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'a_frame_ladders'
                      )
                    " formControlName="a_frame_ladders" />
                  <label for="aframeladdersno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="scaffold" class="label">
                <p>Scaffold</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="scaffoldyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'scaffold')
                    " formControlName="scaffold" />
                  <label for="scaffoldyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="scaffoldno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'scaffold')
                    " formControlName="scaffold" />
                  <label for="scaffoldno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="roofaccess" class="label">
                <p>Roof Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="roofaccessyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'roof_access')
                    " formControlName="roof_access" />
                  <label for="roofaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="roofaccessno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'roof_access')
                    " formControlName="roof_access" />
                  <label for="roofaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="hostingrigging" class="label">
                <p>Hosting/Rigging</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="hostingriggingyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'hosting_rigging'
                      )
                    " formControlName="hosting_rigging" />
                  <label for="hostingriggingyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="hostingriggingno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'hosting_rigging'
                      )
                    " formControlName="hosting_rigging" />
                  <label for="hostingriggingno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="trenchandexcavation" class="label">
                <p>Trench and Excavation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="trenchandexcavationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'trench_and_excavation'
                      )
                    " formControlName="trench_and_excavation" />
                  <label for="trenchandexcavationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="trenchandexcavationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'trench_and_excavation'
                      )
                    " formControlName="trench_and_excavation" />
                  <label for="trenchandexcavationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="hotwork" class="label">
                <p>Hot Work</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="hotworkyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'hot_work')
                    " formControlName="hot_work" />
                  <label for="hotworkyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="hotworkno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'hot_work')
                    " formControlName="hot_work" />
                  <label for="hotworkno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="energizedsystem" class="label">
                <p>Energized System</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="energizedsystemyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'energized_system'
                      )
                    " formControlName="energized_system" />
                  <label for="energizedsystemyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="energizedsystemno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'energized_system'
                      )
                    " formControlName="energized_system" />
                  <label for="energizedsystemno">No</label>
                </span>
              </div>
            </div>
          </div>

          <div class="work-wrap">
            <div class="work-inner">
              <label for="highnoise" class="label">
                <p>High Noise</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoiseyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'high_noise')
                    " formControlName="high_noise" />
                  <label for="highnoiseyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoiseno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'high_noise')
                    " formControlName="high_noise" />
                  <label for="highnoiseno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="limitedaccess" class="label">
                <p>Limited Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'limited_access'
                      )
                    " formControlName="limited_access" />
                  <label for="limitedaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'limited_access'
                      )
                    " formControlName="limited_access" />
                  <label for="limitedaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="confinedspaceaccess" class="label">
                <p>Confined Space Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'confined_space_access'
                      )
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'confined_space_access'
                      )
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="extremeheat" class="label">
                <p>Extreme Heat</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="extremeheatyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'extreme_heat')
                    " formControlName="extreme_heat" />
                  <label for="extremeheatyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="extremeheatno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'extreme_heat')
                    " formControlName="extreme_heat" />
                  <label for="extremeheatno">No</label>
                </span>
              </div>
            </div>
            <!--            <div class="work-inner">-->
            <!--              <label for="other" class="label">-->
            <!--                <p>Other</p>-->
            <!--              </label>-->
            <!--              <div class="redio-wrap">-->
            <!--								<span class="valid">-->
            <!--									<input type="radio" id="otheryes" [value]="1" title="Yes"-->
            <!--                         (change)="setValue('1', 'safety_and_health_hazards','other')"-->
            <!--                         formControlName="other">-->
            <!--									<label for="otheryes">Yes</label>-->
            <!--								</span>-->
            <!--                <span>-->
            <!--									<input type="radio" id="otherno" [value]="0" title="No"-->
            <!--                         (change)="setValue('0', 'safety_and_health_hazards','other')"-->
            <!--                         formControlName="other">-->
            <!--									<label for="otherno">No</label>-->
            <!--								</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="work-inner">
              <label for="exposuretotraffic" class="label">
                <p>Exposure to Traffic</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="exposuretotrafficyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'exposure_to_traffic'
                      )
                    " formControlName="exposure_to_traffic" />
                  <label for="exposuretotrafficyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="exposuretotrafficno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'exposure_to_traffic'
                      )
                    " formControlName="exposure_to_traffic" />
                  <label for="exposuretotrafficno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="slilicaduct" class="label">
                <p>Silica Dust</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="slilicaductyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'slilica_duct')
                    " formControlName="slilica_duct" />
                  <label for="slilicaductyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="slilicaductno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'slilica_duct')
                    " formControlName="slilica_duct" />
                  <label for="slilicaductno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="compressedgas" class="label">
                <p>Compressed Gas</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="compressedgasyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'compressed_gas'
                      )
                    " formControlName="compressed_gas" />
                  <label for="compressedgasyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="compressedgasno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'compressed_gas'
                      )
                    " formControlName="compressed_gas" />
                  <label for="compressedgasno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="creatingfloorroofpenetration" class="label">
                <p>Creating Floor/Roof Penetration</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="creatingfloorroofpenetrationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'creating_floor_roof_penetration'
                      )
                    " formControlName="creating_floor_roof_penetration" />
                  <label for="creatingfloorroofpenetrationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="creatingfloorroofpenetrationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'creating_floor_roof_penetration'
                      )
                    " formControlName="creating_floor_roof_penetration" />
                  <label for="creatingfloorroofpenetrationno">No</label>
                </span>
              </div>
            </div>
            <!-- <div class="work-inner">
              <label for="other1" class="label">
                <p>Other</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input
                    type="radio"
                    id="other1yes"
                    [value]="1"
                    title="Yes"
                    (change)="
                      setValue('1', 'safety_and_health_hazards', 'other1');
                      showOther(true)
                    "
                    formControlName="other1"
                  />
                  <label for="other1yes">Yes</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="other1no"
                    [value]="0"
                    title="No"
                    (change)="
                      setValue('0', 'safety_and_health_hazards', 'other1');
                      showOther(false)
                    "
                    formControlName="other1"
                  />
                  <label for="other1no">No</label>
                </span>
              </div>
            </div> -->

            <div formArrayName="other_list">
              <div
                *ngFor="let ctrl of this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls']; let i = index"
                [formGroupName]="i">
                <div formArrayName="indi_others">
                  <div *ngFor="let others of ctrl.get('indi_others')['controls']; let j = index" [formGroupName]="j"
                    class="others_{{i}}">
                    <div class="work-inner">
                      <label for="other{{j+1}}" class="label">
                        <p>Other {{j+1}} - <span class="editOther" (click)="setValue('1', 'safety_and_health_hazards', 'others', j)" data-toggle="modal" data-target="#add-other-data">Edit</span></p>
                      </label>
                      <div class="redio-wrap">
                        <span class="valid">
                          <input type="radio" id="other{{j+1}}yes" [value]="1" title="Yes"
                            (click)="setValue('1', 'safety_and_health_hazards','others',j)" data-toggle="modal"
                            data-target="#add-other-data" formControlName="value">
                          <label for="other{{j+1}}yes">Yes</label>
                        </span>
                        <span>
                          <input type="radio" id="other{{j+1}}no" [value]="0" title="No"
                            (click)="setValue('0', 'safety_and_health_hazards','others',j)" formControlName="value">
                          <label for="other{{j+1}}no">No</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addBtn" (click)="addAdditionalOthersInfoSameEditTime(ctrl.get('indi_others'))">
                  <a href="javascript:;" class="font-weight-bold text-primary"
                    (click)="addAdditionalOthersInfoSameEditTime(ctrl.get('indi_others'))"></a>
                </div>
              </div>
            </div>
            <!-- <div class="otherFieldset" *ngIf="otherVis">
              <div class="field">
                <label class="label" for="other_description">Description of Work Performed:</label>
                <div class="control">
                  <input id="other_description" title="Description:" value="" class="input-text" type="text"
                    data-validate="{required:true}" aria-required="true" formControlName="other_description" />
                </div>
              </div>
              <div class="field">
                <label class="label" for="other_hazards">Hazards Associated with Each Step:</label>
                <div class="control">
                  <textarea id="other_hazards" title="Hazards:" value="" class="input-text" type="text" rows="4"
                    data-validate="{required:true}" aria-required="true" formControlName="other_hazards" ></textarea>
                </div>
              </div>
              <div class="field">
                <label class="label" for="other_actions">Required Actions to Eliminate or Control the Hazard:</label>
                <div class="control">
                  <textarea id="other_actions" title="Actions:" value="" class="input-text" type="text" rows="6"
                    data-validate="{required:true}" aria-required="true" formControlName="other_actions"></textarea>
                </div>
              </div> 
            </div> -->
          </div>
        </div>
      </div>

      <div class="type-wotk work-permits" formGroupName="work_permits">
        <h2>Work Permits</h2>
        <div class="label-wrap">
          <span class="label req">Required</span>
          <span class="label rec">Received</span>
        </div>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <p>Mobile Equipment</p>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobileequipmentrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'mobile_equipment')" formControlName="mobile_equipment" />
                  <label for="mobileequipmentrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'mobile_equipment')" formControlName="mobile_equipment" />
                  <label for="mobileequipmentrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span>
                  <input type="radio" id="mobileequipmentreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'mobile_equipment_recieved')
                    " formControlName="mobile_equipment_recieved" />
                  <label for="mobileequipmentreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'mobile_equipment_recieved')
                    " formControlName="mobile_equipment_recieved" />
                  <label for="mobileequipmentreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'mobile_equipment_file')" />
              </div>
              <div class="radio-wrap text-right">
                <span id="mobile_equipment_file_name">{{ mobile_equipment_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('mobile_equipment_file')"
                    *ngIf="mobile_equipment_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Man/Personnel Lift</p>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="personnelliftrequiredyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'man_personnel_lift')
                    " formControlName="man_personnel_lift" />
                  <label for="personnelliftrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="personnelliftrequiredno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'man_personnel_lift')
                    " formControlName="man_personnel_lift" />
                  <label for="personnelliftrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="personnelliftreceivedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'work_permits',
                        'man_personnel_lift_recieved'
                      )
                    " formControlName="man_personnel_lift_recieved" />
                  <label for="personnelliftreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="personnelliftreceivedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'work_permits',
                        'man_personnel_lift_recieved'
                      )
                    " formControlName="man_personnel_lift_recieved" />
                  <label for="personnelliftreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'man_personnel_lift_file')" />
              </div>
              <div class="text-right">
                <span id="man_personnel_lift_file_name">{{ man_personnel_lift_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('man_personnel_lift_file')"
                    *ngIf="man_personnel_lift_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Forklift</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'forklift')" formControlName="forklift" />
                  <label for="forkliftrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'forklift')" formControlName="forklift" />
                  <label for="forkliftrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'forklift_recieved')
                    " formControlName="forklift_recieved" />
                  <label for="forkliftreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'forklift_recieved')
                    " formControlName="forklift_recieved" />
                  <label for="forkliftreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'forklift_file')" />
              </div>
              <div class="text-right">
                <span id="forklift_file_name">{{ forklift_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('forklift_file')"
                    *ngIf="forklift_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>High Noise</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoiserequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'high_noise')" formControlName="high_noise" />
                  <label for="highnoiserequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoiserequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'high_noise')" formControlName="high_noise" />
                  <label for="highnoiserequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoisereceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'high_noise_recieved')
                    " formControlName="high_noise_recieved" />
                  <label for="highnoisereceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoisereceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'high_noise_recieved')
                    " formControlName="high_noise_recieved" />
                  <label for="highnoisereceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'high_noise_file')" />
              </div>
              <div class="text-right">
                <span id="high_noise_file_name">{{ high_noise_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('high_noise_file')"
                    *ngIf="high_noise_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Limited Access</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'limited_access')" formControlName="limited_access" />
                  <label for="limitedaccessrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'main_job_list', 'limited_access')" formControlName="limited_access" />
                  <label for="limitedaccessrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'limited_access_recieved')
                    " formControlName="limited_access_recieved" />
                  <label for="limitedaccessreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'main_job_list', 'limited_access_recieved')
                    " formControlName="limited_access_recieved" />
                  <label for="limitedaccessreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'limited_access_file')" />
              </div>
              <div class="text-right">
                <span id="limited_access_file_name">{{ limited_access_file_name }}
                  <i class="fa fa-times spanl-2" (click)="removeFile('limited_access_file')"
                    *ngIf="limited_access_file_name"></i>
                </span>
              </div>
            </div>

            <div class="work-inner">
              <p>Confined Space Access</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessrequiredyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'confined_space_access')
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessrequiredno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'confined_space_access')
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessreceivedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'work_permits',
                        'confined_space_access_recieved'
                      )
                    " formControlName="confined_space_access_recieved" />
                  <label for="confinedspaceaccessreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessreceivedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'work_permits',
                        'confined_space_access_recieved'
                      )
                    " formControlName="confined_space_access_recieved" />
                  <label for="confinedspaceaccessreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'confined_space_access_file')" />
              </div>
              <div class="text-right">
                <span id="confined_space_access_file_name">{{ confined_space_access_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('confined_space_access_file')"
                    *ngIf="confined_space_access_file_name"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-history employee-briefng type-wotk work-permits">
        <h3>Employee Pre-Task Briefing Acknowledgement</h3>

        <div class="wrap">
          <div class="fieldset">
            <div class="field">
              <label class="label" for="name">Supervisor:</label>
              <div class="control">
                <input name="firstname" id="firstname" title="Project:" value="" class="input-text" type="text"
                  data-validate="{required:true}" formControlName="employee_supervisor" aria-required="true" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Date:</label>
              <div class="control">
                <input name="firstname" id="firstname" title="Date:" value="" class="input-text" type="date"
                  data-validate="{required:true}" formControlName="employee_date" aria-required="true" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Supervisor Signature:</label>
              <div class="control">
                <!-- <input name="firstname" id="firstname" title="Job Supervisor/Foreman:" value=""
                  class="input-text" type="text" data-validate="{required:true}"
                  formControlName="employee_signature" aria-required="true"> -->
                <a class="routerLink" (click)="clearSignature()">Clear</a>
                <signature-pad [options]="signaturePadOptions" id="signatureCanvas" (onBeginEvent)="drawBegin()"
                  (onEndEvent)="drawComplete()"></signature-pad>
              </div>
            </div>
          </div>
          <div class="work-wrap">
            <div class="work-inner">
              <label for="beenobtained" class="label">
                <p>Permits have been obtained</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="beenobtainedyes" [value]="1" title="Yes"
                    (change)="setValue('1', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="beenobtainedno" [value]="0" title="No"
                    (change)="setValue('0', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span>
                  <input type="radio" id="beenobtainedna" [value]="2" title="NA"
                    (change)="setValue('2', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="inspectionsperformed" class="label">
                <p>Mobile Equipment Inspections Performed</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="inspectionsperformedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="inspectionsperformedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span>
                  <input type="radio" id="inspectionsperformedna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="equipmentperformed" class="label">
                <p>Pre-Inspections of Powered Tools and Equipment Performed</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="equipmentperformedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="equipmentperformedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span>
                  <input type="radio" id="equipmentperformedna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="cardspresent" class="label">
                <p>Mobile Equipment Operator Cards Present</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="cardspresentyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="cardspresentno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span>
                  <input type="radio" id="cardspresentna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentna">N/A</label>
                </span>
              </div>
            </div>
          </div>
          <div class="actions-toolbar">
            <div class="primary">
              <button type="submit" title="Submit" class="action submit">
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal fade" id="add-other-data" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content pb-0">
      <form action="#">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body pb-0 pt-0">
          <div class="pts-contracting">
            <div class="wrap">
              <div class="otherFieldset">
                <div class="field mb-3">
                  <label class="label mb-1" for="name">Description of Work Performed:</label>
                  <div class="control">
                    <input type="text" class="input-text" [(ngModel)]="description_of_work" name="description_of_work">
                  </div>
                </div>

                <div class="field mb-3">
                  <label class="label mb-1" for="name">Hazards Associated with Each Step:</label>
                  <div class="control">
                    <textarea class="resize-vertical input-text" rows="5"
                      [(ngModel)]="hazards_associated_with_each_step"
                      name="hazards_associated_with_each_step" (keypress)='autoExpand($event)'></textarea>
                  </div>
                </div>

                <div class="field mb-3">
                  <label class="label mb-1" for="name">Required Actions to Eliminate or Control the
                    Hazard:</label>
                  <div class="control">
                    <textarea class="resize-vertical input-text" rows="5"
                      [(ngModel)]="required_action_to_eliminate_hazard"
                      name="required_action_to_eliminate_hazard" (keypress)='autoExpand($event)'></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="submitBtn" data-dismiss="modal" #closeOtherModel>Cancel</button>
          <button type="button" class="submitBtn" (click)="submitOther()">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>