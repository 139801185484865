import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobService } from 'src/app/service/job/job.service';
import { ToastService } from 'src/app/service/shared/toast/toast.service';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { animationFrameScheduler, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { LocalstorageService } from 'src/app/service/shared/localstorage/localstorage.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  jobForm: FormGroup;
  otherVis: false;
  confined_space_access_file_name = '';
  mobile_equipment_file_name = '';
  man_personnel_lift_file_name = '';
  forklift_file_name = '';
  high_noise_file_name = '';
  limited_access_file_name = '';

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  signature;

  description_of_work = '';
  hazards_associated_with_each_step = '';
  required_action_to_eliminate_hazard = '';
  selectedOtherIndex = '';

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild('closeOtherModel') closeOtherModel;
  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 340,
    canvasHeight: 200,
  };

  constructor(
    private formBuilder: FormBuilder,
    private renderer2: Renderer2,
    private jobsService: JobService,
    private toastService: ToastService,
    private storage: AngularFireStorage,
    private router: Router,
    private localStorage: LocalstorageService
  ) {
    this.localStorage.setItem('supervisorSavedSignature', '');
  }

  ngOnInit(): void {
    this.createJobForm();
  }

  //To create permission form
  createJobForm(data = null) {
    if (data != null) {
      let general_and_type_of_work = JSON.parse(data.general_and_type_of_work);
      let safety_and_health_hazards = JSON.parse(
        data.safety_and_health_hazards
      );
      let work_permits = JSON.parse(data.work_permits);
      //Generate form group with permissin data by id
      this.jobForm = this.formBuilder.group({
        project_name: [data.project_name, Validators.required],
        job_supervisor: [data.job_supervisor, Validators.required],
        date_submitted: [data.date_submitted, Validators.required],
        general_and_type_of_work: this.formBuilder.group({
          general_safety: [
            general_and_type_of_work.general_safety
              ? general_and_type_of_work.general_safety
              : 0,
          ],
          mobilization: [
            general_and_type_of_work.mobilization
              ? general_and_type_of_work.mobilization
              : 0,
          ],
          refrigeration: [
            general_and_type_of_work.refrigeration
              ? general_and_type_of_work.refrigeration
              : 0,
          ],
          general_pipe_installation: [
            general_and_type_of_work.general_pipe_installation
              ? general_and_type_of_work.general_pipe_installation
              : 0,
          ],
          duct_installation: [
            general_and_type_of_work.duct_installation
              ? general_and_type_of_work.duct_installation
              : 0,
          ],
          demolition: [
            general_and_type_of_work.demolition
              ? general_and_type_of_work.demolition
              : 0,
          ],
          insulation_duct_pipe: [
            general_and_type_of_work.insulation_duct_pipe
              ? general_and_type_of_work.insulation_duct_pipe
              : 0,
          ],
          asbstos_lead_removal: [
            general_and_type_of_work.asbstos_lead_removal
              ? general_and_type_of_work.asbstos_lead_removal
              : 0,
          ],
          demobilization: [
            general_and_type_of_work.demobilization
              ? general_and_type_of_work.demobilization
              : 0,
          ],
        }),
        safety_and_health_hazards: this.formBuilder.group({
          mobile_equipment: [
            safety_and_health_hazards.mobile_equipment
              ? safety_and_health_hazards.mobile_equipment
              : 0,
          ],
          man_personnel_lift: [
            safety_and_health_hazards.man_personnel_lift
              ? safety_and_health_hazards.man_personnel_lift
              : 0,
          ],
          forklift: [
            safety_and_health_hazards.forklift
              ? safety_and_health_hazards.forklift
              : 0,
          ],
          a_frame_ladders: [
            safety_and_health_hazards.a_frame_ladders
              ? safety_and_health_hazards.a_frame_ladders
              : 0,
          ],
          scaffold: [
            safety_and_health_hazards.scaffold
              ? safety_and_health_hazards.scaffold
              : 0,
          ],
          roof_access: [
            safety_and_health_hazards.roof_access
              ? safety_and_health_hazards.roof_access
              : 0,
          ],
          hosting_rigging: [
            safety_and_health_hazards.hosting_rigging
              ? safety_and_health_hazards.hosting_rigging
              : 0,
          ],
          trench_and_excavation: [
            safety_and_health_hazards.trench_and_excavation
              ? safety_and_health_hazards.trench_and_excavation
              : 0,
          ],
          hot_work: [
            safety_and_health_hazards.hot_work
              ? safety_and_health_hazards.hot_work
              : 0,
          ],
          energized_system: [
            safety_and_health_hazards.energized_system
              ? safety_and_health_hazards.energized_system
              : 0,
          ],
          high_noise: [
            safety_and_health_hazards.high_noise
              ? safety_and_health_hazards.high_noise
              : 0,
          ],
          limited_access: [
            safety_and_health_hazards.limited_access
              ? safety_and_health_hazards.limited_access
              : 0,
          ],
          confined_space_access: [
            safety_and_health_hazards.confined_space_access
              ? safety_and_health_hazards.confined_space_access
              : 0,
          ],
          extreme_heat: [
            safety_and_health_hazards.extreme_heat
              ? safety_and_health_hazards.extreme_heat
              : 0,
          ],
          // "other": [safety_and_health_hazards.other ? safety_and_health_hazards.other : 0],
          exposure_to_traffic: [
            safety_and_health_hazards.exposure_to_traffic
              ? safety_and_health_hazards.exposure_to_traffic
              : 0,
          ],
          slilica_duct: [
            safety_and_health_hazards.slilica_duct
              ? safety_and_health_hazards.slilica_duct
              : 0,
          ],
          compressed_gas: [
            safety_and_health_hazards.compressed_gas
              ? safety_and_health_hazards.compressed_gas
              : 0,
          ],
          creating_floor_roof_penetration: [
            safety_and_health_hazards.creating_floor_roof_penetration
              ? safety_and_health_hazards.creating_floor_roof_penetration
              : 0,
          ],
          // other1: [
          //   safety_and_health_hazards.other1
          //     ? safety_and_health_hazards.other1
          //     : 0,
          // ],
          other_list: this.formBuilder.array([this.addAdditionalOthersInfoRows(safety_and_health_hazards && safety_and_health_hazards.other_list ? safety_and_health_hazards.other_list : null)]),
          other_description: [data.other_description],
          other_hazards: [data.other_hazards],
          other_actions: [data.other_actions],
        }),
        work_permits: this.formBuilder.group({
          mobile_equipment: [
            work_permits.mobile_equipment ? work_permits.mobile_equipment : 0,
          ],
          man_personnel_lift: [
            work_permits.man_personnel_lift
              ? work_permits.man_personnel_lift
              : 0,
          ],
          forklift: [work_permits.forklift ? work_permits.forklift : 0],
          high_noise: [work_permits.high_noise ? work_permits.high_noise : 0],
          limited_access: [
            work_permits.limited_access ? work_permits.limited_access : 0,
          ],
          confined_space_access: [
            work_permits.confined_space_access
              ? work_permits.confined_space_access
              : 0,
          ],
        }),
        id: [data.id],
      });
    } else {
      //Generate form group for add roles and permission
      this.jobForm = this.formBuilder.group({
        project_name: ['', Validators.required],
        job_supervisor: ['', Validators.required],
        date_submitted: ['', Validators.required],
        general_and_type_of_work: this.formBuilder.group({
          general_safety: [1],
          mobilization: [1],
          refrigeration: [0],
          general_pipe_installation: [0],
          duct_installation: [0],
          demolition: [0],
          insulation_duct_pipe: [0],
          asbstos_lead_removal: [0],
          demobilization: [1],
        }),
        safety_and_health_hazards: this.formBuilder.group({
          mobile_equipment: [0],
          man_personnel_lift: [0],
          forklift: [0],
          a_frame_ladders: [0],
          scaffold: [0],
          roof_access: [0],
          hosting_rigging: [0],
          trench_and_excavation: [0],
          hot_work: [0],
          energized_system: [0],
          high_noise: [0],
          limited_access: [0],
          confined_space_access: [0],
          extreme_heat: [0],
          // "other": [0],
          exposure_to_traffic: [0],
          slilica_duct: [0],
          compressed_gas: [0],
          creating_floor_roof_penetration: [0],
          // other1: [0],
          other_list: this.formBuilder.array([this.addAdditionalOthersInfoRows()]),
          other_description: [''],
          other_hazards: [''],
          other_actions: [''],
        }),
        work_permits: this.formBuilder.group({
          mobile_equipment: [0],
          mobile_equipment_recieved: [0],
          mobile_equipment_file: [''],
          man_personnel_lift: [0],
          man_personnel_lift_recieved: [0],
          man_personnel_lift_file: [''],
          forklift: [0],
          forklift_recieved: [0],
          forklift_file: [0],
          high_noise: [0],
          high_noise_recieved: [0],
          high_noise_file: [''],
          limited_access: [0],
          limited_access_recieved: [0],
          limited_access_file: [''],
          confined_space_access: [0],
          confined_space_access_recieved: [0],
          confined_space_access_file: [''],
        }),
        id: [''],
        employee_supervisor: [''],
        employee_date: [''],
        employee_signature: [''],
        permits_have_been_obtained: [''],
        mobile_equipment_inspections_performed: [''],
        preinspections_of_powered_tools_and_equipment_performed: [''],
        mobile_equipment_operator_cards_present: [''],
      });
    }
  }

  // Add additional data in permit type form
  addAdditionalOthersInfoRows(data = null) {
    /* Add new rows to existing dates */
    let group;
    group = this.formBuilder.group({
      indi_others: this.formBuilder.array([])
    });

    //If data exist, set data in permit type form
    if (data != null) {
      data.forEach((element, index) => {
        this.addAdditionalOthersInfoSameEditTime(group.controls.indi_others, element);
      });
    } else {
      this.addAdditionalOthersInfoSameEditTime(group.controls.indi_others, data);
    }

    return group;
  }

  // Add form parameter in group
  addAdditionalOthersInfoSameEditTime(others: any, data = null) {
    /* Add new requests to existing dates */ //debugger

    let group;
    if (data != null) {
      //If data exist, set data in fields
      group = this.formBuilder.group({
        value: [data.value ? data.value : 0],
        description_of_work: data.description_of_work,
        hazards_associated_with_each_step: data.hazards_associated_with_each_step,
        required_action_to_eliminate_hazard: data.required_action_to_eliminate_hazard
      });

      others.push(group);
    } else {

      group = this.formBuilder.group({
        value: 0,
        description_of_work: '',
        hazards_associated_with_each_step: '',
        required_action_to_eliminate_hazard: ''
      });

      others.push(group);
    }

  }

  //Set 0/1 on checkbox checked false/true
  setValue(event, formGroupName, key, index = null) {
    if (formGroupName != '') {
      if (key == "others") {
        this.selectedOtherIndex = index;
        if (event == 1) {
          this.description_of_work = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['description_of_work'].value;
          this.hazards_associated_with_each_step = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['hazards_associated_with_each_step'].value;
          this.required_action_to_eliminate_hazard = this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['required_action_to_eliminate_hazard'].value;
          this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['value'].setValue(1);
        } else {
          this.description_of_work = '';
          this.hazards_associated_with_each_step = '';
          this.required_action_to_eliminate_hazard = '';
          this.jobForm.get(formGroupName)['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][index]['controls']['value'].setValue(0);
          this.submitOther();
          // this.enableOtherInput = false;
          // this.jobForm
          //   .get(formGroupName)
          // ['controls']['other1_description_of_work'].setValue('Other - ');
          // this.jobForm
          //   .get(formGroupName)
          // ['controls']['other1_hazards_associated_with_each_step'].setValue('');
          // this.jobForm
          //   .get(formGroupName)
          // ['controls']['other1_required_action_to_eliminate_hazard'].setValue('');

        }
      }
      if (key != '' && index == null) {
        this.jobForm
          .get(formGroupName)
        ['controls'][key].setValue(event == 1 ? 1 : 0);
      }
    } else {
      if (key != '') {
        this.jobForm.get(key).setValue(event == 1 ? 1 : event == 2 ? 2 : 0);
      }
    }
  }

  onSubmit(form) {
    const formProjectName = document.querySelector(
      '#project_name'
    ) as HTMLInputElement;
    const formDateSubmitted = document.querySelector(
      '#firstname'
    ) as HTMLInputElement;
    const formJobSupervisor = document.querySelector(
      '#job_supervisor'
    ) as HTMLInputElement;
    const reqFields = [formProjectName, formDateSubmitted, formJobSupervisor];
    const submitToast = () =>
      this.toastService.showToast('Please fill in required fields', 'error');

    switch ('') {
      case formProjectName.value:
        reqFields.forEach((e) => {
          e.focus();
          e.blur();
        });
        formProjectName.focus();
        submitToast();
        break;
      case formDateSubmitted.value:
        reqFields.forEach((e) => {
          e.focus();
          e.blur();
        });
        formDateSubmitted.focus();
        submitToast();
        break;
      case formJobSupervisor.value:
        reqFields.forEach((e) => {
          e.focus();
          e.blur();
        });
        formJobSupervisor.focus();
        submitToast();
        break;
      default:
        if (
          this.jobForm.get('work_permits')['controls']['mobile_equipment_file']
            .value
        ) {
          if (
            this.jobForm.get('work_permits')['controls'][
              'mobile_equipment_file'
            ].value.name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
              'mobile_equipment_file'
            ].value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls'][
                'mobile_equipment_file'
              ].value
            );
            form.value.work_permits.mobile_equipment_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (
          this.jobForm.get('work_permits')['controls'][
            'man_personnel_lift_file'
          ].value
        ) {
          if (
            this.jobForm.get('work_permits')['controls'][
              'man_personnel_lift_file'
            ].value.name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
              'man_personnel_lift_file'
            ].value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls'][
                'man_personnel_lift_file'
              ].value
            );
            form.value.work_permits.man_personnel_lift_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (
          this.jobForm.get('work_permits')['controls']['forklift_file'].value
        ) {
          if (
            this.jobForm.get('work_permits')['controls']['forklift_file'].value
              .name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['forklift_file']
              .value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls']['forklift_file']
                .value
            );
            form.value.work_permits.forklift_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (
          this.jobForm.get('work_permits')['controls']['high_noise_file'].value
        ) {
          if (
            this.jobForm.get('work_permits')['controls']['high_noise_file']
              .value.name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls']['high_noise_file']
              .value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls']['high_noise_file']
                .value
            );
            form.value.work_permits.high_noise_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (
          this.jobForm.get('work_permits')['controls']['limited_access_file']
            .value
        ) {
          if (
            this.jobForm.get('work_permits')['controls']['limited_access_file']
              .value.name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
              'limited_access_file'
            ].value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls'][
                'limited_access_file'
              ].value
            );
            form.value.work_permits.limited_access_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (
          this.jobForm.get('work_permits')['controls'][
            'confined_space_access_file'
          ].value
        ) {
          if (
            this.jobForm.get('work_permits')['controls'][
              'confined_space_access_file'
            ].value.name
          ) {
            var n = `${Date.now()}_${this.jobForm.get('work_permits')['controls'][
              'confined_space_access_file'
            ].value.name
              }`;
            const filePath = `uploads/pts/permits/${n}`;
            const fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(
              `uploads/pts/permits/${n}`,
              this.jobForm.get('work_permits')['controls'][
                'confined_space_access_file'
              ].value
            );
            form.value.work_permits.confined_space_access_file = n;
            task
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.downloadURL = fileRef.getDownloadURL();
                  this.downloadURL.subscribe((url) => {
                    if (url) {
                    }
                  });
                })
              )
              .subscribe((url) => {
                if (url) {
                  console.log(url);
                }
              });
          }
        }
        if (form.invalid) {
          this.jobForm.markAllAsTouched();
        } else {
          //Check for if role has id or not
          if (
            form.value.id != '' &&
            form.value.id != null &&
            form.value.id != undefined
          ) {
            //Update role and permission if id exist
            this.jobsService.updateJob(form.value.id, form.value).subscribe(
              (res) => this.onJobUpdateSuccess(res),
              (error) => this.onJobUpdateError(error)
            );
          } else {
            //Add new role and permission if id does not exist
            delete form.value.id;
            form.value.safety_and_health_hazards.other_list = this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others'].value;
            form.value.employee_signature = this.localStorage.getItem(
              'supervisorSavedSignature'
            );
            this.jobsService.createJob(form.value).subscribe(
              (res) => this.onJobUpdateSuccess(res),
              (error) => this.onJobUpdateError(error)
            );
          }
        }
    }
  }

  //On success of add/update permission api
  onJobUpdateSuccess(result) {
    if (result) {
      if (result.data) {
        //this.router.navigateByUrl('/dashboard');
        this.localStorage.setItem('supervisorSavedSignature', '');
        this.signaturePad.clear();
        this.router.navigate(['/updatejobs', { jobId: result.data.id }]);
        this.toastService.showToast('Job added successfully', 'success');
      }
    }
  }

  //On error of add/update permission api
  onJobUpdateError(error) {
    this.localStorage.setItem('supervisorSavedSignature', '');
    this.signaturePad.clear();
    this.toastService.showToast('Job Error', 'error');
  }

  onFileSelect(event, type) {
    if (event.target.files.length > 0) {
      let flag = 1;
      const file = event.target.files[0];

      if (event.target.files.length === 0) return;

      setTimeout(() => {
        this.jobForm.get('work_permits')['controls'][type].setValue(file);
        var reader = new FileReader();
        if (type == 'mobile_equipment_file') {
          this.mobile_equipment_file_name = event.target.files[0].name;
        }
        if (type == 'man_personnel_lift_file') {
          this.man_personnel_lift_file_name = event.target.files[0].name;
        }
        if (type == 'forklift_file') {
          this.forklift_file_name = event.target.files[0].name;
        }
        if (type == 'high_noise_file') {
          this.high_noise_file_name = event.target.files[0].name;
        }
        if (type == 'limited_access_file') {
          this.limited_access_file_name = event.target.files[0].name;
        }
        if (type == 'confined_space_access_file') {
          this.confined_space_access_file_name = event.target.files[0].name;
        }

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => { };
      }, 500);
    }
  }

  removeFile(type) {
    this.jobForm.get('work_permits')['controls'][type].setValue('');
    if (type == 'mobile_equipment_file') {
      this.mobile_equipment_file_name = '';
    }
    if (type == 'man_personnel_lift_file') {
      this.man_personnel_lift_file_name = '';
    }
    if (type == 'forklift_file') {
      this.forklift_file_name = '';
    }
    if (type == 'high_noise_file') {
      this.high_noise_file_name = '';
    }
    if (type == 'limited_access_file') {
      this.limited_access_file_name = '';
    }
    if (type == 'confined_space_access_file') {
      this.confined_space_access_file_name = '';
    }
  }

  public drawBegin(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png');
    this.localStorage.setItem('supervisorSavedSignature', this.signature);
  }

  clearSignature() {
    //var signaturePad = new SignaturePad(this.signaturePad);
    this.localStorage.setItem('supervisorSavedSignature', '');
    this.signaturePad.clear();
  }

  showOther(e) {
    this.otherVis = e;
  }

  submitOther() {
    if (Number(this.selectedOtherIndex) > -1) {
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['description_of_work'].setValue(this.description_of_work);
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['hazards_associated_with_each_step'].setValue(this.hazards_associated_with_each_step);
      this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls'][0]['controls']['indi_others']['controls'][this.selectedOtherIndex]['controls']['required_action_to_eliminate_hazard'].setValue(this.required_action_to_eliminate_hazard);
      this.description_of_work = '';
      this.hazards_associated_with_each_step = '';
      this.required_action_to_eliminate_hazard = '';
    }
    this.closeOtherModel.nativeElement.click();
  }

  autoExpand(e) {
    // Reset field height
    const field = e.target;
    field.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(field);
    console.log(field.scrollHeight);

    // Calculate the height
    const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                + parseInt(computed.getPropertyValue('padding-top'), 10)
                + field.scrollHeight
                + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 5 + 'px';
  }
}
