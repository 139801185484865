import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessCodeGeneratorService } from 'src/app/service/access-code-generator/access-code-generator.service';
import { LocalstorageService } from 'src/app/service/shared/localstorage/localstorage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  newCode = "";
  notCorrect = false;
  notValid = false;
  lastGeneratedCodeDetails = [];
  constructor(public accessCodeGeneratorService: AccessCodeGeneratorService,
    public localStorage: LocalstorageService,
    public router: Router) { }

  ngOnInit(): void {
    this.localStorage.removeItem('userCode');
  }

  submitCode() {
    if (this.newCode) {
      this.getLatestAccessCodeDetails();
      this.notValid = false;
    } else {
      document.getElementById('accessCode').classList.add('errorTextBox');
      this.notValid = true;
    }
  }

  getLatestAccessCodeDetails() {
    this.accessCodeGeneratorService.getLatestCode().subscribe(
      (res) => this.onGetAccessCodeSuccess(res),
      (error) => this.onGetAccessCodeError(error)
    );
  }

  onGetAccessCodeSuccess(result) {
    if (result) {
      if (result.data) {
        this.lastGeneratedCodeDetails = result.data[0];
        if (this.lastGeneratedCodeDetails['code'] == this.newCode) {
          this.localStorage.setItem('userCode', this.newCode);
          this.localStorage.setSessionItem('userCode', this.newCode);
          this.router.navigateByUrl('/dashboard');
          document.getElementById('accessCode').classList.remove('errorTextBox');
        } else {
          document.getElementById('accessCode').classList.add('errorTextBox');
          this.notCorrect = true;
        }
      }
    }
  }

  onGetAccessCodeError(error) {
  }
}
