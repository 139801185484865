<div class="pts-contracting jsa-detail record">
  <div class="container">
    <header>
      <div class="text-wrap">
        <p>PTS Contracting Safety Precaution and Pre-Job Brief Requirements</p>
        <span>Job Safety Analysis (JSA)</span>
      </div>
      <div class="logo-wrap">
        <a routerLink="/" class="logo"><img src="../../../assets/images/logo.jpg" title="PTS Contracting"
            alt="PTS Contracting" /></a>
      </div>
    </header>
    <form [formGroup]="jobForm" (ngSubmit)="onSubmit(jobForm)" *ngIf="jobForm">
      <div class="create-history">
        <h3>Project Details</h3>
        <div class="wrap">
          <div class="fieldset">
            <div class="field">
              <label class="label" for="name">Project:</label>
              <div class="control">
                <input id="project_name" title="Project:" value="" class="input-text" type="text"
                  data-validate="{required:true}" aria-required="true" formControlName="project_name" />
                <span class="error" *ngIf="
                    jobForm.get('project_name').hasError('required') &&
                    jobForm.get('project_name').touched
                  ">
                  Please provide project name
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Project Start Date:</label>
              <div class="control">
                <input id="firstname" title="Date:" value="" class="input-text" type="date"
                  data-validate="{required:true}" aria-required="true" formControlName="date_submitted" />
                <span class="error" *ngIf="
                    jobForm.get('date_submitted').hasError('required') &&
                    jobForm.get('date_submitted').touched
                  ">
                  Please provide date submitted
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Job Supervisor/Foreman:</label>
              <div class="control">
                <input id="firstname" title="Job Supervisor/Foreman:" value="" class="input-text" type="text"
                  data-validate="{required:true}" aria-required="true" formControlName="job_supervisor" />
                <span class="error" *ngIf="
                    jobForm.get('job_supervisor').hasError('required') &&
                    jobForm.get('job_supervisor').touched
                  ">
                  Please provide job supervisor/foreman
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="type-wotk" formGroupName="general_and_type_of_work">
        <h2>General and Type of Work</h2>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <label for="general_safety" class="label">
                <p>General Safety</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="generalsafetyyes" #generalsafetyyes [value]="1" checked title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'general_safety'
                      )
                    " formControlName="general_safety" />
                  <label for="generalsafetyyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="generalsafetyno" #generalsafetyno [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'general_safety'
                      )
                    " formControlName="general_safety" />
                  <label for="generalsafetyno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="mobilization" class="label">
                <p>Mobilization</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobilizationyes" checked [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'mobilization')
                    " formControlName="mobilization" />
                  <label for="mobilizationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobilizationno" [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'mobilization')
                    " formControlName="mobilization" />
                  <label for="mobilizationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="refrigeration" class="label">
                <p>Refrigeration</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="refrigerationyes" #refrigerationyes [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'refrigeration')
                    " formControlName="refrigeration" />
                  <label for="refrigerationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="refrigerationno" #refrigerationno [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'refrigeration')
                    " formControlName="refrigeration" />
                  <label for="refrigerationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="general_pipe_installation" class="label">
                <p>Pipe Installation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="general_pipe_installationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'general_pipe_installation'
                      )
                    " formControlName="general_pipe_installation" />
                  <label for="general_pipe_installationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="general_pipe_installationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'general_pipe_installation'
                      )
                    " formControlName="general_pipe_installation" />
                  <label for="general_pipe_installationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="duct_installation" class="label">
                <p>Duct Installation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="duct_installationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'duct_installation'
                      )
                    " formControlName="duct_installation" />
                  <label for="duct_installationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="duct_installationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'duct_installation'
                      )
                    " formControlName="duct_installation" />
                  <label for="duct_installationno">No</label>
                </span>
              </div>
            </div>
          </div>
          <div class="work-wrap">
            <div class="work-inner">
              <label for="demolition" class="label">
                <p>Demolition</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="demolitionyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'general_and_type_of_work', 'demolition')
                    " formControlName="demolition" />
                  <label for="demolitionyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="demolitionyesno" [value]="0" title="No" (change)="
                      setValue('0', 'general_and_type_of_work', 'demolition')
                    " formControlName="demolition" />
                  <label for="demolitionyesno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="insulationductpipe" class="label">
                <p>Insulation Duct/Pipe</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="insulationductpipeyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'insulation_duct_pipe'
                      )
                    " formControlName="insulation_duct_pipe" />
                  <label for="insulationductpipeyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="insulationductpipeno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'insulation_duct_pipe'
                      )
                    " formControlName="insulation_duct_pipe" />
                  <label for="insulationductpipeno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="asbestosleadremoval" class="label">
                <p>Asbestos Lead Removal</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="asbestosleadremovalyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'asbstos_lead_removal'
                      )
                    " formControlName="asbstos_lead_removal" />
                  <label for="asbestosleadremovalyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="asbestosleadremovalno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'asbstos_lead_removal'
                      )
                    " formControlName="asbstos_lead_removal" />
                  <label for="asbestosleadremovalno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="demobilization" class="label">
                <p>Demobilization</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="demobilizationyes" checked [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'general_and_type_of_work',
                        'demobilization'
                      )
                    " formControlName="demobilization" />
                  <label for="demobilizationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="demobilizationyesno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'general_and_type_of_work',
                        'demobilization'
                      )
                    " formControlName="demobilization" />
                  <label for="demobilizationyesno">No</label>
                </span>
              </div>
            </div>
          </div>
          <div class="actions-toolbar">
            <a class="view-all link-pointer" (click)="showGeneralPtp()">View PTP>
            </a>
          </div>
          <div id="general-ptp" class="create-history ptp hide">
            <form class="form">
              <table role="presentation" style="margin: auto; max-width: 100%" class="main-table" cellspacing="0"
                cellpadding="0" border="0" bgcolor="#ffffff">
                <tr>
                  <td>
                    <table role="presentation" class="content-wrape" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td class="mobile-height navmenutd" style="">PTP</td>
                        </tr>
                        <tr>
                          <td class="pad-mobile-10 first">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Description of Work Performed
                                  </td>
                                  <td class="service-head">
                                    Hazards Associated with Each Step
                                  </td>
                                  <td class="service-head">
                                    Required Actions to Eliminate or Control the
                                    Hazard
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isGeneralSafety">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">General Safety</td>
                                  <td class="service-head">
                                    1. Protect Personnel<br />
                                    2. COVID -19
                                  </td>
                                  <td class="service-head">
                                    1a. Assure all employees are fit for duty
                                    and wearing the proper PPE for the task.
                                    MINIMUM PPE includes Hard Hats, Safety
                                    Glasses, Short Sleeve Shirts, Long Pants and
                                    Safety Shoes.<br />
                                    1b. Assure all employees are trained for the
                                    tasks they are performing before
                                    workbegins<br />
                                    1c. Power tools connected to GFCI.<br />
                                    2a. Fill out daily health check before
                                    entering the job site. If an employee needs
                                    to answer YES to any of the health
                                    questions, they cannot enter the job site
                                    and will need to self-quarantine
                                    immediately.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isMobilization">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Mobilize tools, equipment, and materials to
                                    the jobsite
                                  </td>
                                  <td class="service-head">
                                    1. Unsafe Loading<br />
                                    2. Unsafe Equipment, Ladders or Tools<br />
                                    3. Untrained Operators<br />
                                    4. Personnel Injury<br />
                                    • Pinch points<br />
                                    • Sprainsandstrains<br />
                                    • Cuts andScrapes<br />
                                    • Slip,trip or falls<br />
                                    • Caughtbetween<br />
                                    5. Improper Liftingand Carrying<br />
                                    6. Leaving materials and equipment in
                                    unsecured areas or in an unsecured
                                    condition.
                                  </td>
                                  <td class="service-head">
                                    1a. Vehicles and trailers should be chocked,
                                    and loads strapped down safely when entering
                                    the site.<br />
                                    2a. An annual inspection of any piece of
                                    equipment utilized on a PTS job site must be
                                    completed and available.<br />
                                    2b. A documented daily inspection will
                                    beconducted by the equipment operator prior
                                    to use to ensure equipment is in good
                                    operating condition.<br />
                                    2c. Any tool or piece of equipment that
                                    arrives broken or is damaged during
                                    unloading and staging shall be red tagged
                                    and if necessary, removedfrom the job for
                                    service.<br />
                                    3a. Equipment to be operated by trained
                                    personnel only.<br />
                                    4a. When manually handling loads, employees
                                    should maintain an unrestricted view of the
                                    pathway ahead and use proper lifting
                                    technique.<br />
                                    4b. Employees should identify and remove
                                    possible laceration and puncture hazards.<br />
                                    4c. Employees should carry tools by the
                                    handle.<br />
                                    5a. Manual material handling should be
                                    limited to no greater than 50lbs per person.
                                    Mechanical means should be made available
                                    for loads exceeding 50lbs<br />
                                    5b. Employees are encouraged to pre-plan
                                    each lift and use team lifting whenever
                                    possibleEmployees are encouraged to use
                                    mechanical means to transport materialsand
                                    equipment.<br />
                                    6a. All materials, equipment and tools left
                                    on site must be left in a secure fashion.<br />
                                    6b. Use cribbing, chocking, locks including
                                    wheel locks and cross hatched stacking to
                                    ensure area is secure.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isRefrigeration">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Refrigeration</td>
                                  <td class="service-head">
                                    1. Electric Shock/Electrocution<br />
                                    2. Chemical Inhalation, Explosion<br />
                                    3. Cold Burns<br />
                                    4. Improper Service
                                  </td>
                                  <td class="service-head">
                                    1a. Turn off all electrical equipment.<br />
                                    1b. Use lockout and tag procedures.<br />
                                    1c. Verify circuits are deenergized using
                                    theappropriate multimeter.<br />
                                    1d. Remove jewelry and clothing made of
                                    synthetic fiber or other
                                    conductivematerials.<br />
                                    1e. Make sure the ground and surrounding
                                    surfaces are dry before operating electrical
                                    equipment.<br />
                                    2a. Remove gas cylinders from work vehicles
                                    on hot days.<br />
                                    2b. Weigh and inspect gas cylinders before
                                    filling.<br />
                                    2c. Check valves for damage, leaks, or
                                    tampering.<br />
                                    2d. Do not use cylinders that are
                                    damaged,rusted, dented, or gouged.<br />
                                    2e. Store refrigerant cylinders upright with
                                    the valves at the top.<br />
                                    2f. Keep cylinders in dry locations.<br />
                                    3a. Wear gloves, long pants and long-sleeved
                                    shirts when working around chilled piping,
                                    tubing, and components. Frostbite can be
                                    almost instantaneous on exposed skin.<br />
                                    4a. Follow manufacturer guidelines when
                                    servicing equipment.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isInstallationOfPiping">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Installation of Piping
                                  </td>
                                  <td class="service-head">
                                    1. Material handling, Cuts and scrapes<br />
                                    2. Pinch Points<br />
                                    3. Sprains and strains<br />
                                    4. Slip, trips, and falls<br />
                                    5. Falling object
                                  </td>
                                  <td class="service-head">
                                    1a. Use cut resistant gloves and sleeves,<br />
                                    1b. Be aware of other workers<br />
                                    1c. Use the right tools for the job1d. Check
                                    tools and equipment before each use<br />
                                    2a. Watch for pinch points.<br />
                                    3a. Lift withlegs anddonottwist
                                    whilelifting<br />
                                    3b. Use mechanical means for heavy loads<br />
                                    3c. Use a minimum of two workers for awkward
                                    or heavy materials<br />
                                    4a. Observe grade changes while walking<br />
                                    4b. Bemindfulofthe weightandcenterof
                                    gravitymaterials<br />
                                    4c. Keep work area clean<br />
                                    5a. Never walk under overhead work<br />
                                    5b. Secure tools and equipment when working
                                    aloft.<br />
                                    5c. Look and feel when removing from and
                                    securing a tool in a tool pouch.<br />
                                    5d. Ensure kick plates are in use to prevent
                                    tools and materials from falling to the
                                    ground.<br />
                                    5e. Demarcate fall area to restrict entry of
                                    unauthorized personnel.<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isInstallationOfDuctwork">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Installation of Ductwork
                                  </td>
                                  <td class="service-head">
                                    1. Material handling, Cuts and scrapes<br />
                                    2. Pinch Points<br />
                                    3. Sprains and strains<br />
                                    4. Slip, trips, and falls<br />
                                    5. Falling object
                                  </td>
                                  <td class="service-head">
                                    1a. Use cut resistant gloves and sleeves,<br />
                                    1b. Be aware of other workers<br />
                                    1c. Use the right tools for the job<br />
                                    1d. Check tools and equipment before each
                                    use<br />
                                    2a. Watchforpinchpoints.<br />
                                    3a. Lift withlegs anddonottwist
                                    whilelifting<br />
                                    3b. Use mechanical means for heavy loads<br />
                                    3c. Use a minimum of two workers for awkward
                                    or heavy materials<br />
                                    4a. Observe grade changes while walking<br />
                                    4b. Bemindful of the weight and center of
                                    gravity materials<br />
                                    4c. Keep work area clean<br />
                                    5a. Never walk under overhead work<br />
                                    5b. Secure tools and equipment when working
                                    aloft.<br />
                                    5c. Look and feel when removing from and
                                    securing a tool in a tool pouch.<br />
                                    5d. Ensure kick plates are in use to prevent
                                    tools and materials from falling to the
                                    ground.<br />
                                    5e. Demarcate fall area to restrict entry of
                                    unauthorized personnel.<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isDemolition">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Demolition</td>
                                  <td class="service-head">
                                    1. Improper Cut/Cap/ Deenergizing.<br />
                                    2. Injury from falling objects.<br />
                                    3. Material handling, Cuts and scrapes<br />
                                    4. Pinch Points<br />
                                    5. Sprains and strains<br />
                                    6. Slip, trips, and falls<br />
                                    7. Falling object
                                  </td>
                                  <td class="service-head">
                                    1a. The Competent person in conjunction with
                                    facility management and project engineer
                                    determines items to be
                                    cut/capped/deenergized and methods to be
                                    utilized.<br />
                                    2a. Demarcate fall area to restrict entry of
                                    unauthorized personnel.<br />
                                    2b. Never walk under overhead work2c.
                                    Control the path of items being
                                    demolished<br />
                                    3a. Use cut resistant gloves and sleeves,<br />
                                    3b. Be aware of other workers3c. Use the
                                    right tools for the job<br />
                                    3d. Check tools and equipment before each
                                    use<br />
                                    4a. Watchforpinchpoints.<br />
                                    5a. Lift withlegs anddonottwist
                                    whilelifting<br />
                                    5b. Use mechanical means for heavy loads<br />
                                    5c. Use a minimum of two workers for awkward
                                    or heavy materials<br />
                                    6a. Observe grade changes while walking<br />
                                    6b. Bemindfulofthe weightandcenterof
                                    gravitymaterials<br />
                                    6c. Keep work area clean<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isInsulationOfPiping">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Insulation of Piping or Ductwork
                                  </td>
                                  <td class="service-head">
                                    1. Cuts and Scrapes<br />
                                    2. Slip,trip or falls<br />
                                    3. Objects fall from above<br />
                                  </td>
                                  <td class="service-head">
                                    1a. Use cut resistant gloves and sleeves,<br />
                                    1b. Be aware of other workers<br />
                                    1c. Use the right tools for the job<br />
                                    1d. Check tools and equipment before each
                                    use<br />
                                    2a. Keep work area clean<br />
                                    2b. Watch where you are walking<br />
                                    2c. If you must use your cell phone
                                    stop,step out of the work area.<br />
                                    3a. Never walk under overhead work<br />
                                    3b. Secure tools and equipment when working
                                    aloft.<br />
                                    3c. Look and feel when removing from
                                    andsecuring a tool in a tool pouch.<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isAsbestos">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Asbestos Removal/Encapsulation
                                  </td>
                                  <td class="service-head">
                                    1. Untrained people in the work area.<br />
                                    2. Persons donning respirators who are
                                    physically unfit to do so.<br />
                                    3. Inhalation of Respirable Asbestos
                                    Fibers<br />
                                    4. Contaminating area outside the work
                                    site<br />
                                    5. Proper disposal of ACM materials<br />
                                  </td>
                                  <td class="service-head">
                                    1. Maintain DANGER asbestos signs at access
                                    / egress points. Assure all other personnel
                                    in the area are aware of the activities and
                                    the hazards. Utilized banner tape and
                                    physical barriers as necessary to keep
                                    untrained and unauthorizedpeople out of the
                                    work area.<br />
                                    2a. Current Physical exam, physicians
                                    written opinion, fit test, and training
                                    certification on site foreach employee prior
                                    to starting work.<br />
                                    2b. A trained, designated competent person
                                    will be responsible for all aspects of the
                                    work process.<br />
                                    3a. Air purifying respirators with HEPA
                                    cartridges, disposable outer garments and
                                    gloves worn during ACM removal.<br />
                                    3b. OSHA Air Monitoring will be conducted
                                    daily.<br />
                                    3c. Air monitoring results will determine
                                    the need for changes in respiratory
                                    protection.<br />
                                    3d. Change respirator cartridges as
                                    necessary to ensure less restricted
                                    breathing.<br />
                                    4a. Negative pressure enclosures with HEPA
                                    filtration units and directly connected
                                    decontamination units.<br />
                                    4b. HEPA vacuums will be attached to the
                                    glove-bags to maintain negative pressure and
                                    control airborne fibers.<br />
                                    4c. Contaminated PPE will be washed or HEPA
                                    vacuumed as appropriate prior to leaving
                                    work area and will remain in dirty area of
                                    the remote decontamination unit at lunch and
                                    upon completion of daily activities.<br />
                                    4d. ACM, contaminated PPE, contaminated HEPA
                                    filters to be placed in sealed, labeled
                                    containers for disposal.<br />
                                    4e. All ACM to be kept thoroughly wetted
                                    before and during removal.<br />
                                    4f. ACM to be placed into sealed containers
                                    while still wet in preparation for
                                    disposal.<br />
                                    4g. Debris from ACM will be cleaned up by
                                    the completion of each shift.<br />
                                    4h. The area will pass a visual inspection
                                    and receive a clean air check prior to
                                    removing barriers and signs.<br />
                                    5a. All ACM to be disposed of in an approved
                                    landfill.<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isDemobilization">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Demobilize tools, equipment, and materials
                                    from the jobsite
                                  </td>
                                  <td class="service-head">
                                    1. Unsafe Loadingor Transportation<br />
                                    2. Unsafe Equipment Ladders or Tools<br />
                                    3. Untrained Operators<br />
                                    4. Personnel Injury<br />
                                    • Pinch points<br />
                                    • Sprains and strains<br />
                                    • Cuts and Scrapes<br />
                                    • Slip, trip or falls<br />
                                    • Caught between<br />
                                    5. Improper Liftingand Carrying<br />
                                    6. Leaving the Site in an Unsafe Manner
                                    (poor housekeeping)
                                  </td>
                                  <td class="service-head">
                                    1a. Vehicles and trailers should be chocked,
                                    and loads strapped down safely prior to
                                    leaving the site.<br />
                                    2a. Any tool or piece of equipment that is
                                    broken or is damaged during
                                    loading/transportation/unloading shall be
                                    red tagged and if necessary, removed from
                                    service.<br />
                                    3a. Equipment to be operatedby trained
                                    personnel only.<br />
                                    4a. When manually handling loads, employees
                                    should maintain an unrestricted view of the
                                    pathway ahead and use proper lifting
                                    technique.<br />
                                    4b. Employees should identify and remove
                                    possible laceration and puncture hazards.<br />
                                    4c. Employees should carry tools by the
                                    handle.<br />
                                    5a. Manual material handling should be
                                    limited to no greater than 50lbs per person.
                                    Mechanical means should be made available
                                    for loads exceeding 50lbs<br />
                                    5b. Employees are encouraged to pre-plan
                                    each lift and use team lifting whenever
                                    possible Employees are encouraged to use
                                    mechanical means to transport materialsand
                                    equipment.<br />
                                    6a. Prior to exiting the jobsite, all PTSI
                                    materials,equipment, and debris will be
                                    removed from the jobsite. A final
                                    walkthrough will be conducted with the GC to
                                    ensure PTSIwork areas are clean and free of
                                    hazard6b. Work areas will be left in a clean
                                    sanitary condition.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>

      <div class="type-wotk health-hazards" formGroupName="safety_and_health_hazards">
        <h2>Safety and Health Hazards</h2>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <label for="mobileequipment" class="label">
                <p>Mobile Equipment</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobileequipmentyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'mobile_equipment'
                      )
                    " formControlName="mobile_equipment" />
                  <label for="mobileequipmentyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'mobile_equipment'
                      )
                    " formControlName="mobile_equipment" />
                  <label for="mobileequipmentno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="manpersonnellift" class="label">
                <p>Man/Personnel Lift</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="manpersonnelliftyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'man_personnel_lift'
                      )
                    " formControlName="man_personnel_lift" />
                  <label for="manpersonnelliftyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="manpersonnelliftno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'man_personnel_lift'
                      )
                    " formControlName="man_personnel_lift" />
                  <label for="manpersonnelliftno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="forklift" class="label">
                <p>Forklift</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'forklift')
                    " formControlName="forklift" />
                  <label for="forkliftyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'forklift')
                    " formControlName="forklift" />
                  <label for="forkliftno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="aframeladders" class="label">
                <p>A-Frame Ladders</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="aframeladdersyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'a_frame_ladders'
                      )
                    " formControlName="a_frame_ladders" />
                  <label for="aframeladdersyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="aframeladdersno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'a_frame_ladders'
                      )
                    " formControlName="a_frame_ladders" />
                  <label for="aframeladdersno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="scaffold" class="label">
                <p>Scaffold</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="scaffoldyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'scaffold')
                    " formControlName="scaffold" />
                  <label for="scaffoldyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="scaffoldno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'scaffold')
                    " formControlName="scaffold" />
                  <label for="scaffoldno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="roofaccess" class="label">
                <p>Roof Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="roofaccessyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'roof_access')
                    " formControlName="roof_access" />
                  <label for="roofaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="roofaccessno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'roof_access')
                    " formControlName="roof_access" />
                  <label for="roofaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="hostingrigging" class="label">
                <p>Hosting/Rigging</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="hostingriggingyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'hosting_rigging'
                      )
                    " formControlName="hosting_rigging" />
                  <label for="hostingriggingyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="hostingriggingno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'hosting_rigging'
                      )
                    " formControlName="hosting_rigging" />
                  <label for="hostingriggingno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="trenchandexcavation" class="label">
                <p>Trench and Excavation</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="trenchandexcavationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'trench_and_excavation'
                      )
                    " formControlName="trench_and_excavation" />
                  <label for="trenchandexcavationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="trenchandexcavationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'trench_and_excavation'
                      )
                    " formControlName="trench_and_excavation" />
                  <label for="trenchandexcavationno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="hotwork" class="label">
                <p>Hot Work</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="hotworkyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'hot_work')
                    " formControlName="hot_work" />
                  <label for="hotworkyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="hotworkno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'hot_work')
                    " formControlName="hot_work" />
                  <label for="hotworkno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="energizedsystem" class="label">
                <p>Energized System</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="energizedsystemyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'energized_system'
                      )
                    " formControlName="energized_system" />
                  <label for="energizedsystemyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="energizedsystemno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'energized_system'
                      )
                    " formControlName="energized_system" />
                  <label for="energizedsystemno">No</label>
                </span>
              </div>
            </div>
          </div>

          <div class="work-wrap">
            <div class="work-inner">
              <label for="highnoise" class="label">
                <p>High Noise</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoiseyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'high_noise')
                    " formControlName="high_noise" />
                  <label for="highnoiseyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoiseno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'high_noise')
                    " formControlName="high_noise" />
                  <label for="highnoiseno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="limitedaccess" class="label">
                <p>Limited Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'limited_access'
                      )
                    " formControlName="limited_access" />
                  <label for="limitedaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'limited_access'
                      )
                    " formControlName="limited_access" />
                  <label for="limitedaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="confinedspaceaccess" class="label">
                <p>Confined Space Access</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'confined_space_access'
                      )
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'confined_space_access'
                      )
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="extremeheat" class="label">
                <p>Extreme Heat</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="extremeheatyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'extreme_heat')
                    " formControlName="extreme_heat" />
                  <label for="extremeheatyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="extremeheatno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'extreme_heat')
                    " formControlName="extreme_heat" />
                  <label for="extremeheatno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="exposuretotraffic" class="label">
                <p>Exposure to Traffic</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="exposuretotrafficyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'exposure_to_traffic'
                      )
                    " formControlName="exposure_to_traffic" />
                  <label for="exposuretotrafficyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="exposuretotrafficno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'exposure_to_traffic'
                      )
                    " formControlName="exposure_to_traffic" />
                  <label for="exposuretotrafficno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="slilicaduct" class="label">
                <p>Silica Dust</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="slilicaductyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'safety_and_health_hazards', 'slilica_duct')
                    " formControlName="slilica_duct" />
                  <label for="slilicaductyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="slilicaductno" [value]="0" title="No" (change)="
                      setValue('0', 'safety_and_health_hazards', 'slilica_duct')
                    " formControlName="slilica_duct" />
                  <label for="slilicaductno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="compressedgas" class="label">
                <p>Compressed Gas</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="compressedgasyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'compressed_gas'
                      )
                    " formControlName="compressed_gas" />
                  <label for="compressedgasyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="compressedgasno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'compressed_gas'
                      )
                    " formControlName="compressed_gas" />
                  <label for="compressedgasno">No</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="creatingfloorroofpenetration" class="label">
                <p>Creating Floor/Roof Penetration</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="creatingfloorroofpenetrationyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'safety_and_health_hazards',
                        'creating_floor_roof_penetration'
                      )
                    " formControlName="creating_floor_roof_penetration" />
                  <label for="creatingfloorroofpenetrationyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="creatingfloorroofpenetrationno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'safety_and_health_hazards',
                        'creating_floor_roof_penetration'
                      )
                    " formControlName="creating_floor_roof_penetration" />
                  <label for="creatingfloorroofpenetrationno">No</label>
                </span>
              </div>
            </div>
            <!-- <div class="work-inner">
              <label for="other1" class="label">
                <p>Other</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input
                    type="radio"
                    id="other1yes"
                    [value]="1"
                    title="Yes"
                    (change)="
                      setValue('1', 'safety_and_health_hazards', 'other1')
                    "
                    formControlName="other1"
                  />
                  <label for="other1yes">Yes</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="other1no"
                    [value]="0"
                    title="No"
                    (change)="
                      setValue('0', 'safety_and_health_hazards', 'other1')
                    "
                    formControlName="other1"
                  />
                  <label for="other1no">No</label>
                </span>
              </div>
            </div> -->
            <div formArrayName="other_list">
              <div
                *ngFor="let ctrl of this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls']; let i = index"
                [formGroupName]="i">
                <div formArrayName="indi_others">
                  <div *ngFor="let others of ctrl.get('indi_others')['controls']; let j = index" [formGroupName]="j"
                    class="others_{{i}}">
                    <div class="work-inner">
                      <label for="other{{j+1}}" class="label">
                        <p>Other {{j+1}} - <span class="editOther" (click)="setValue('1', 'safety_and_health_hazards', 'others', j)"
                            data-toggle="modal" data-target="#add-other-data">Edit</span></p>
                      </label>
                      <div class="redio-wrap">
                        <span class="valid">
                          <input type="radio" id="other{{j+1}}yes" [value]="1" title="Yes"
                            (click)="setValue('1', 'safety_and_health_hazards','others',j)" data-toggle="modal"
                            data-target="#add-other-data" formControlName="value">
                          <label for="other{{j+1}}yes">Yes</label>
                        </span>
                        <span>
                          <input type="radio" id="other{{j+1}}no" [value]="0" title="No"
                            (click)="setValue('0', 'safety_and_health_hazards','others',j)" formControlName="value">
                          <label for="other{{j+1}}no">No</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addBtn" (click)="addAdditionalOthersInfoSameEditTime(ctrl.get('indi_others'))">
                  <a href="javascript:;" class="font-weight-bold text-primary"
                    (click)="addAdditionalOthersInfoSameEditTime(ctrl.get('indi_others'))"></a>
                </div>
              </div>
            </div>
            <!-- <div
              class="otherFieldset hidden"
              formGroupName="other"
              *ngIf="otherVis"
            >
              <div class="field">
                <label class="label" for="other_description"
                  >Description of Work Performed:</label
                >
                <div class="control">
                  <input
                    id="other_description"
                    title="Description:"
                    value=""
                    class="input-text"
                    type="text"
                    data-validate="{required:true}"
                    aria-required="true"
                    formControlName="other_description"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label" for="other_hazards"
                  >Hazards Associated with Each Step:</label
                >
                <div class="control">
                  <textarea
                    id="other_hazards"
                    title="Hazards:"
                    value=""
                    class="input-text"
                    type="text"
                    rows="4"
                    data-validate="{required:true}"
                    aria-required="true"
                    formControlName="other_hazards"
                  ></textarea>
                </div>
              </div>
              <div class="field">
                <label class="label" for="other_actions"
                  >Required Actions to Eliminate or Control the Hazard:</label
                >
                <div class="control">
                  <textarea
                    id="other_actions"
                    title="Actions:"
                    value=""
                    class="input-text"
                    type="text"
                    rows="6"
                    data-validate="{required:true}"
                    aria-required="true"
                    formControlName="other_actions"
                  ></textarea>
                </div>
              </div>
            </div> -->
          </div>

          <div class="actions-toolbar">
            <a class="view-all link-pointer" (click)="showSafetyPtp()">View PTP>
            </a>
          </div>

          <div id="safety-ptp" class="create-history ptp hide">
            <form class="form" [formGroup]="jobForm">
              <table role="presentation" style="margin: auto; max-width: 100%" class="main-table" cellspacing="0"
                cellpadding="0" border="0" bgcolor="#ffffff">
                <tr>
                  <td>
                    <table role="presentation" class="content-wrape" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td class="mobile-height navmenutd" style="">PTP</td>
                        </tr>
                        <tr>
                          <td class="pad-mobile-10 first">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Description of Work Performed
                                  </td>
                                  <td class="service-head">
                                    Hazards Associated with Each Step
                                  </td>
                                  <td class="service-head">
                                    Required Actions to Eliminate or Control the
                                    Hazard
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isMobileEquipment">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Mobile Equipment Use
                                  </td>
                                  <td class="service-head">
                                    1. Faulty Equipment <br />
                                    2. Operation by Untrained Personnel
                                  </td>
                                  <td class="service-head">
                                    1a. An annual inspection of any piece of
                                    equipment utilized on a PTS job site must be
                                    completed and available. <br />
                                    1b. A documented daily inspection will be
                                    conducted by the equipment operator prior to
                                    use to ensure equipment is in good operating
                                    condition. <br />
                                    1c. Back-up alarm, running lights, inspected
                                    fire extinguisher, and daily inspection
                                    sheets will be maintained on the unit at all
                                    times. <br />
                                    2a. Only authorized equipment operators may
                                    use equipment on the jobsite. PTSI issued
                                    authorization card must always remain on the
                                    operator . <br />
                                    2b. A spotter will be used for loads that
                                    extend beyond the body of the lift and in
                                    close quarters to personnel and equipment.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isManPersonnelLift">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Man/Personnel Lift Use
                                  </td>
                                  <td class="service-head">
                                    1. Faulty Equipment <br />
                                    2. Untrained Personnel <br />
                                    3. Personnel Injury <br />
                                    • Pinch Points<br />
                                    • Caught In Between<br />
                                    • Falling out of lift
                                  </td>
                                  <td class="service-head">
                                    1a. Equipment inspected before use. <br />
                                    2a. Equipment to be operated by trained and
                                    certified personnel only. <br />
                                    3a. Review the work area for ground and
                                    overhead<br />
                                    2a. Equipment to be operatedby trained and
                                    certified personnel only.<br />
                                    3a. Review the work area for ground
                                    andoverhead hazards.<br />
                                    3b. Watch for pinch points<br />
                                    3c. Be aware of grade changes and objects on
                                    the ground<br />
                                    3d. When entering and exiting the manlift.
                                    Three points of contact.<br />
                                    3e. All persons in boom lifts must wear
                                    asafety harness 100% of the time.<br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isForklift">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Forklift Use</td>
                                  <td class="service-head">
                                    1. Faulty Equipment<br />
                                    2. Untrained Personnel<br />
                                    3. Personnel Injury<br />
                                    • Pinch Points<br />
                                    • Caught In Between<br />
                                    4. Property Damage<br />
                                    5. Equipment and Material Damage
                                  </td>
                                  <td class="service-head">
                                    1a. Equipment inspected before use.<br />
                                    2a. Equipment to be operatedby trained and
                                    certified personnel only.<br />
                                    3a. Review the work area for ground
                                    andoverhead hazards.<br />
                                    3b. Watch for pinch points<br />
                                    3c. Be aware of grade changes and objects on
                                    the ground4a. Spotter must accompany
                                    forklift when carryinga load. Check pathway
                                    for debris and clear path.<br />
                                    4b. Be mindful of the weight and center of
                                    gravity of the load.<br />
                                    5a. Use cribbing to set material on and
                                    chock objects that can roll.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isAFrameLadders">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">A-Frame Ladders</td>
                                  <td class="service-head">
                                    1. Unsafe Equipment<br />
                                    2. Falls
                                  </td>
                                  <td class="service-head">
                                    1a. Ladders must be inspected prior to
                                    use.<br />
                                    1b. Any defects found the ladder must be red
                                    tagged and removed from service.<br />
                                    2a. Use 3 points of contact while
                                    ascendingand descending the ladder.<br />
                                    2b. Make sure you face the ladder at all
                                    times while using it. Keep your waist
                                    between the ladder supports.<br />
                                    2c. Make sure it is placed opened up
                                    fullyand on solid a solid surface prior to
                                    use.<br />
                                    2d. If working at or above the 6.0 foot you
                                    must be tied off using a retractable and
                                    fullbody harness attached to a strong anchor
                                    point capable of supporting 5000 lbs.<br />
                                    2e. Get help when having to lift or carry
                                    items up the ladder.<br />
                                    2f. Do not lean the A frame ladder and use
                                    it.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isScaffold">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Scaffold</td>
                                  <td class="service-head">
                                    1. Scaffold Failure<br />
                                    2. Fall from Scaffold<br />
                                    3. Drop or kick items onto people below
                                  </td>
                                  <td class="service-head">
                                    1a. Scaffold erection to be designed
                                    andauthorized by a competent person. 2b.
                                    Scaffold to be inspected daily andgreen
                                    carded prior to use.<br />
                                    2a. Safety rails to be present or employees
                                    to wear fall protection whileon the
                                    scaffold.<br />
                                    3a. Toe rails and/or safety nets to be
                                    inplace to insure items do not fall on
                                    people below.<br />
                                    3b. Be careful when handling tools and when
                                    removing them from or placingthem in a tool
                                    pouch. Keep a firm gripon tools and
                                    materials until place in asecure position.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isRoofAccess">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Roof Access</td>
                                  <td class="service-head">
                                    1. Falls when accessing roof.<br />
                                    2. Falls from roof<br />
                                    3. Distracted work
                                  </td>
                                  <td class="service-head">
                                    1a. Use three points of contact when
                                    climbing a ships ladder or an extension
                                    ladder. Extension ladders when use should be
                                    secured to the roof via a bungee strap or
                                    other means.<br />
                                    2a. Keep hands free when climbing a ladder.
                                    Use a rope or other means to get materials
                                    and tools on the roof.<br />
                                    2a. Use a warning line and guardrail
                                    system<br />
                                    2b. Use a warning line and safety net
                                    system<br />
                                    2c. Use a warning line and personal fall
                                    arrest system<br />
                                    2d. Use a warning line and safety monitoring
                                    system; or<br />
                                    2e. Use only a monitoring system on roofs
                                    50-feet or less in width<br />
                                    2d. A warning line is used 15 feet or more
                                    from the edge; The warning line meets or
                                    exceeds therequirements in §1926.502(f)(2);
                                    No work or work-related activity is to take
                                    place in the area between the warning line
                                    and the edge; The employer effectively
                                    implements a work rule prohibiting the
                                    employees from going past the warning
                                    line.<br />
                                    3a. Use of a cell phone is prohibited on the
                                    roofunless the user is in a stationary
                                    position.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isHostingRigging">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Hoisting & Rigging
                                  </td>
                                  <td class="service-head">
                                    1. Faulty Equipment<br />
                                    2. Untrained Personnel<br />
                                    3. Caught Between (load and other
                                    equipment)<br />
                                    4. Crush or Struck By (suspended load)<br />
                                    5. Pinch Point (when positioning or removing
                                    slings)
                                  </td>
                                  <td class="service-head">
                                    1a. Before performing the lift verify that
                                    all lifting equipment and rigging
                                    accessories are current with allRegulatory
                                    Standard inspection requirements.<br />
                                    1b. Verify that all required forms
                                    orchecklists have been completed prior
                                    toperforming any lifting activities1c.
                                    Forklift attachments utilized inhoisting
                                    activities are required to meetthe
                                    requirements found in OSHA1910.178(a).<br />
                                    2a. Crane operators shall be licensed
                                    asrequired according to OSHA 1926.1427.
                                    Their credentials must be included withthe
                                    lift plan.<br />
                                    2b. Have a hoisting and rigging plan inplace
                                    on site.<br />
                                    2c. Follow the requirements of the hoisting
                                    and rigging plan procedures ofthe “PTSI
                                    Hoisting and Rigging Plan”3,4,5. Personnel
                                    shall not be positionedunder any suspended
                                    load. Non-essential personnel are not
                                    permittedwithin the established lift zone.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isHotWork">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Hot Work (Potential for Fire)
                                  </td>
                                  <td class="service-head">
                                    1. Fire (burn Injuries, smoke Inhalation)<br />
                                    • Pressurized (bulging) container<br />
                                    • Environmental Insult (due to toxic smoke
                                    and other by-products of fire)<br />
                                    • Equipment damage<br />
                                    • Property damage
                                  </td>
                                  <td class="service-head">
                                    1a. See jobsite representative and request a
                                    signed burn permit<br />
                                    1b. Follow burn permit procedures<br />
                                    1c. Remove all combustible materials within
                                    35’ of area of hot work<br />
                                    1d. Fire extinguishers to have inspection
                                    tags and be maintained current<br />
                                    1e. Provide a minimum 10lb ABC fire
                                    extinguisher within 25’ of hot work
                                    locations<br />
                                    1f. Provide minimum 10lb ABC fire
                                    extinguisher within 75’ of gas or diesel
                                    equipment & fuel storage areas<br />
                                    1g. Assign an employee as “fire watch” with
                                    immediate access to a fire extinguisher for
                                    a minimum of 60 minutes after the heat or
                                    last sparks are created.<br />
                                    1h. Brief all project workers to the
                                    locations of the fire extinguishers, manual
                                    fire alarm pull stations, and other means of
                                    reporting emergencies intheir work areas.<br />
                                    1i. Brief all project workers to the
                                    emergency evacuation routes, designated
                                    assembly points, andapplicable additional
                                    information required for responding to fire
                                    or other emergencies in the project work
                                    area.<br />
                                    1j. Ensure access to each of the following
                                    is maintained clear and unobstructed at all
                                    times:<br />
                                    A. Fire Department systems (e.g., fire
                                    hydrants,fire extinguishers, fire department
                                    sprinkler system connection points,
                                    etc.),<br />
                                    B. Emergency response equipment, and<br />
                                    C. Emergency means of egress.<br />
                                    1k. Maintain a minimum of two (2) different
                                    emergency access locations into the project
                                    work area for respondingsite emergency
                                    response equipment
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isEnergizedSystem">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Electrically Energized Systems
                                  </td>
                                  <td class="service-head">1. Electrocution</td>
                                  <td class="service-head">
                                    1a. Only individuals trained and certified
                                    for the energy rating of the system can
                                    deenergize or reenergize the system.<br />
                                    1b. Deenergized systems that need to be
                                    serviced shall be locked out/tagged out in
                                    such a way that the individual (s) working
                                    on the system are in control of at least one
                                    step needed for reenergizing the system.<br />
                                    1c. Any systems or piece of equipment that
                                    are being demolished or temporarily
                                    deenergized shall be made safe according to
                                    the most recent deenergizing standards for
                                    the electricalrating of the system or piece
                                    of equipment.<br />
                                    1d. Never cut a lock off a locked-out system
                                    (yes people lose keys) without getting a
                                    written sign off from every person and or
                                    entity who is a part of the lockout tagout
                                    process.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isHighNoise">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">High Noise</td>
                                  <td class="service-head">
                                    1. Loss of hearing<br />
                                    2. Loss of communication
                                  </td>
                                  <td class="service-head">
                                    1a. Wear hearing protection rated for the
                                    highest sound level the employee will be
                                    subjected to.<br />
                                    2a. Be aware of surroundings and other clues
                                    that there may be something wrong outside of
                                    your immediate work. For instance, someone
                                    may be trying to communicate to you that
                                    there is a different hazard in your area but
                                    you are not able to hear their warning or
                                    direction.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isLimitedAccess">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Restricted Entry Space (Not Confined Space)
                                  </td>
                                  <td class="service-head">
                                    1. Rodents, varmints, and pests (black
                                    widows, snakes, etc.)<br />
                                    2. Slips, trips, and falls; electrical
                                    hazards.<br />
                                    3. Backup Lighting<br />
                                    4. Exist Strategy<br />
                                    5. Ventilation
                                  </td>
                                  <td class="service-head">
                                    1a. Be aware of the possibility of
                                    encountering vermin, pests, snakes, stinging
                                    insects. Be prepared to remove yourself from
                                    the space if necessary, until such can be
                                    removed.<br />
                                    2a. Be aware of unsafe work left by others.
                                    This is more likely in restricted spaces. Be
                                    prepared to remove yourself from the area
                                    until proper procedures are in place.<br />
                                    3a. Carry a flashlight (a working charged
                                    cell phone may suffice) incase someone shuts
                                    off the lights.<br />
                                    4a. Know where the possible exits are.
                                    Insure you cannot get locked in before
                                    entry.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isConfinedSpaceAccess">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Permit Required Confined Space
                                  </td>
                                  <td class="service-head">
                                    1. Improper training on confined space work
                                    and/or an incorrect understanding of the
                                    specific confined space the work is in.<br />
                                    2. Incorrect, PPE., or lack of understanding
                                    on how to don/doff PPE<br />
                                    3. Atmosphere not monitored correctly.
                                    Monitor malfunctions or is not calibrated.
                                    Area not properly ventilated.<br />
                                    3. Engulfment hazards, respiratory hazards,
                                    chemical/bio-hazardous material
                                    (splashing).<br />
                                    4. Hoist fails, fall arrest system fails to
                                    function, fall into pit or through hatch at
                                    surface level.<br />
                                    5. Lack of Communication or
                                    Miscommunication<br />
                                    6. Slips, trips, and falls (ladder slippery
                                    or damp, fall into pit).<br />
                                    7. Ergonomic issues from lifting equipment
                                    and gear from confined space, and
                                    respiratory hazards.<br />
                                    8. Attendant could fall into open pit or be
                                    overcome by a hazardous release from the
                                    pit.<br />
                                    9. Pinch hazards closing confined space
                                    hatch; slips, trips, and falls; respiratory
                                    hazards, electrical hazards,<br />
                                    10. Engulfment in space when exiting.
                                  </td>
                                  <td class="service-head">
                                    1a. ensure SOP, written outlining required
                                    steps for entering confined spaces and
                                    confined space training.<br />
                                    1b. Training for confined space and on the
                                    job, training conducted by experienced
                                    employees (shift ops. train new
                                    employees).<br />
                                    1b. Inspect workplace prior to work
                                    starting.<br />
                                    2a. Insure all employees have been properly
                                    trained on the use of the PPE necessary for
                                    the safe completion of the tasks.<br />
                                    2b. Inspect PPE prior to start of work each
                                    day for serviceability (steel toe boots,
                                    safety glasses).<br />
                                    3a. Verify calibration and test machine
                                    before leaving work center. Test at all
                                    levelsin space to ensure all levels are free
                                    of gases.<br />
                                    3b. Turn ventilation on to rid confined
                                    space of gases and ensure that permit to
                                    enter confined space is obtained.<br />
                                    4a. Inspect hoist/fall systems for wear and
                                    damage, always utilize a fall arrester.
                                    Ensure attendant is wearing fall protection
                                    gear and can visually watch workers in the
                                    space.<br />
                                    5a. Establish a communication system between
                                    the employee(s) in the confined space and
                                    the attendant(s) outside of the confined
                                    space.<br />
                                    6a. Confined spaces can be slippery. Always
                                    use three points of contact when entering
                                    and exiting the space. Look before you step
                                    or touch. Be aware of overhead hazards.<br />
                                    7a. Move slowly. Lifting with a restricted
                                    ceiling can make lifting with your legs more
                                    difficult. Restricted spaces also invited
                                    twisting and stretching movements to reach
                                    tools and equipment. Move slow. Move
                                    smart.<br />
                                    8a. The attendant to wear PPE and safety
                                    harness at all times. Don prior to worker
                                    entry into confined space and do not remove
                                    until all workers, material, equipment, and
                                    spoils are removed and theconfined space is
                                    sealed.<br />
                                    9a. Wear gloves that will minimize pinch
                                    hazards when closing hatch to pits.<br />
                                    10a. Ensure SOP is in place for exiting
                                    pits.<br />
                                    10b. Keep all PPE on until you have
                                    completely exited the confined space. Check
                                    to insure you have removed all
                                    tools,equipment, and any debris from the
                                    confined space before removing any PPE.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isExtremeHeat">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Extreme Heat</td>
                                  <td class="service-head">
                                    1. Burns<br />
                                    2. Heat Exhaustion
                                  </td>
                                  <td class="service-head">
                                    1. Wear gloves and/or aprons to protect from
                                    burns.<br />
                                    2. Drink lots of fluids.<br />
                                    3. Take additional breaks.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isExposureToTraffic">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Exposure to Traffic
                                  </td>
                                  <td class="service-head">
                                    1. Lack of Communication<br />
                                    2. Motorist Confusion<br />
                                    3. Workers exposed to traffic<br />
                                    4. Removing Barriers and Communication Tools
                                  </td>
                                  <td class="service-head">
                                    1a. Communicate with local authorities if
                                    necessary.<br />
                                    1b. Communicate with DOT and other
                                    contractors<br />
                                    2a. Place signs, cones, and warning lights
                                    slowly and carefully as you are shifting the
                                    normal flow of traffic.<br />
                                    2b. For bridge work. Check both ends of
                                    bridge to ensure barriers are in proper
                                    place before start of every shift.<br />
                                    3a. Ensure barriers are in place when
                                    necessary to protect works from traffic.<br />
                                    3b. If barriers are moved, they must be put
                                    back in place after access is gained by
                                    workers.<br />
                                    3c. If motorist enters work area, STOP work,
                                    and assist the motorist to get them out of
                                    the work area safely.<br />
                                    4a. Be careful when removing traffic
                                    barriers and motorist communication tools as
                                    this may lead to confusion for some
                                    motorists.<br />
                                    4b. Stay aware of your surroundings.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isSilicaDuct">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Silica Dust</td>
                                  <td class="service-head">
                                    1. Breathing in silica dust<br />
                                    2. Exposing others to silica dust
                                  </td>
                                  <td class="service-head">
                                    1a. Use wet methods or tools equipped with
                                    dust shrouds connected to a HEPA filtration
                                    system. When cutting or coring.<br />
                                    1b. If engineering methods above are not
                                    adequate to eliminate visible exposure
                                    utilized respirators to protect workers.<br />
                                    2a. Use critical barriers and HEPA
                                    filtration devises to contain silica dust
                                    inwork area.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isCompressedGas">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">Compressed Gas</td>
                                  <td class="service-head">1. Explosion</td>
                                  <td class="service-head">
                                    1a. Store compressed air containers upright
                                    and in a cool location.<br />
                                    1b. Secure compressed air containers when
                                    transporting and storing.<br />
                                    1c. Weigh and inspect gas cylinders before
                                    filling.<br />
                                    1d. Check valves for damage, leaks, or
                                    tampering.<br />
                                    1e. Do not use cylinders that are
                                    damaged,rusted, dented, or gouged.<br />
                                    1f. Do not leave compressed air containers
                                    in a truck on a hot day.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="isCreatingFloorRoofPenetration">
                          <td class="pad-mobile-10 secound">
                            <table role="presentation" class="mobile-inner-content-wrape" width="100%" cellspacing="0"
                              cellpadding="0" border="0">
                              <tbody>
                                <tr>
                                  <td class="service-head">
                                    Creating Floor/Roof Penetration
                                  </td>
                                  <td class="service-head">
                                    1. Lack of Communication<br />
                                    2. Fall/ Death<br />
                                    3. Weather Access
                                  </td>
                                  <td class="service-head">
                                    1a. Notify others in the work area that
                                    thepenetration is going to be created.<br />
                                    1b. Demarcate the area with barrier tape and
                                    signs prior to creating the penetration.<br />
                                    2a. Temporarily or permanently fill the
                                    penetration or isolate it with guardrails
                                    prior to leaving the work area.<br />
                                    3a. Weatherproof the temporary or permanent
                                    fill solution prior to leaving at the end of
                                    your work shift.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr *ngIf="enableOtherInput">
                          <td class="pad-mobile-10 secound" formGroupName="safety_and_health_hazards">
                            <div formArrayName="other_list">
                              <div
                                *ngFor="let ctrl of this.jobForm.get('safety_and_health_hazards')['controls']['other_list']['controls']; let i = index"
                                [formGroupName]="i">
                                <div formArrayName="indi_others">
                                  <table role="presentation" class="mobile-inner-content-wrape" width="100%"
                                    cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                      <tr *ngFor="let others of ctrl.get('indi_others')['controls']; let j = index"
                                        [formGroupName]="j" class="others_{{i}}">
                                        <ng-container *ngIf="others.get('value').value == 1">
                                          <td class="service-head va-m">
                                            <input type="text" formControlName="description_of_work">
                                          </td>
                                          <td class="service-head va-m">
                                            <textarea rows="8" class="resize-vertical ptpText"
                                              formControlName="hazards_associated_with_each_step" (keypress)='autoExpand($event)'ng-init='autoInit($this)'></textarea>
                                          </td>
                                          <td class="service-head va-m">
                                            <textarea rows="8" class="resize-vertical ptpText"
                                              formControlName="required_action_to_eliminate_hazard" (keypress)='autoExpand($event)'></textarea>
                                          </td>
                                        </ng-container>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>

      <div class="type-wotk work-permits" formGroupName="work_permits">
        <h2>Work Permits</h2>
        <div class="label-wrap">
          <span class="label req">Required</span>
          <span class="label rec">Received</span>
        </div>
        <div class="wrap">
          <div class="work-wrap">
            <div class="work-inner">
              <p>Mobile Equipment</p>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="mobileequipmentrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'mobile_equipment')" formControlName="mobile_equipment" />
                  <label for="mobileequipmentrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'mobile_equipment')" formControlName="mobile_equipment" />
                  <label for="mobileequipmentrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span>
                  <input type="radio" id="mobileequipmentreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'mobile_equipment_recieved')
                    " formControlName="mobile_equipment_recieved" />
                  <label for="mobileequipmentreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="mobileequipmentreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'mobile_equipment_recieved')
                    " formControlName="mobile_equipment_recieved" />
                  <label for="mobileequipmentreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'mobile_equipment_file')" />
              </div>
              <div class="radio-wrap text-right">
                <span id="mobile_equipment_file_name">{{ mobile_equipment_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('mobile_equipment_file')"
                    *ngIf="mobile_equipment_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Man/Personnel Lift</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="personnelliftrequiredyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'man_personnel_lift')
                    " formControlName="man_personnel_lift" />
                  <label for="personnelliftrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="personnelliftrequiredno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'man_personnel_lift')
                    " formControlName="man_personnel_lift" />
                  <label for="personnelliftrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="personnelliftreceivedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'work_permits',
                        'man_personnel_lift_recieved'
                      )
                    " formControlName="man_personnel_lift_recieved" />
                  <label for="personnelliftreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="personnelliftreceivedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'work_permits',
                        'man_personnel_lift_recieved'
                      )
                    " formControlName="man_personnel_lift_recieved" />
                  <label for="personnelliftreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'man_personnel_lift_file')" />
              </div>
              <div class="text-right">
                <span id="man_personnel_lift_file_name">{{ man_personnel_lift_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('man_personnel_lift_file')"
                    *ngIf="man_personnel_lift_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Forklift</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'forklift')" formControlName="forklift" />
                  <label for="forkliftrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'forklift')" formControlName="forklift" />
                  <label for="forkliftrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="forkliftreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'forklift_recieved')
                    " formControlName="forklift_recieved" />
                  <label for="forkliftreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="forkliftreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'forklift_recieved')
                    " formControlName="forklift_recieved" />
                  <label for="forkliftreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'forklift_file')" />
              </div>
              <div class="text-right">
                <span id="forklift_file_name">{{ forklift_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('forklift_file')"
                    *ngIf="forklift_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>High Noise</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoiserequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'high_noise')" formControlName="high_noise" />
                  <label for="highnoiserequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoiserequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'work_permits', 'high_noise')" formControlName="high_noise" />
                  <label for="highnoiserequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="highnoisereceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'high_noise_recieved')
                    " formControlName="high_noise_recieved" />
                  <label for="highnoisereceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="highnoisereceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'high_noise_recieved')
                    " formControlName="high_noise_recieved" />
                  <label for="highnoisereceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'high_noise_file')" />
              </div>
              <div class="text-right">
                <span id="high_noise_file_name">{{ high_noise_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('high_noise_file')"
                    *ngIf="high_noise_file_name"></i></span>
              </div>
            </div>

            <div class="work-inner">
              <p>Limited Access</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessrequiredyes" [value]="1" title="Yes"
                    (change)="setValue('1', 'work_permits', 'limited_access')" formControlName="limited_access" />
                  <label for="limitedaccessrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessrequiredno" [value]="0" title="No"
                    (change)="setValue('0', 'main_job_list', 'limited_access')" formControlName="limited_access" />
                  <label for="limitedaccessrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="limitedaccessreceivedyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'limited_access_recieved')
                    " formControlName="limited_access_recieved" />
                  <label for="limitedaccessreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="limitedaccessreceivedno" [value]="0" title="No" (change)="
                      setValue('0', 'main_job_list', 'limited_access_recieved')
                    " formControlName="limited_access_recieved" />
                  <label for="limitedaccessreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'limited_access_file')" />
              </div>
              <div class="text-right">
                <span id="limited_access_file_name">{{ limited_access_file_name }}
                  <i class="fa fa-times spanl-2" (click)="removeFile('limited_access_file')"
                    *ngIf="limited_access_file_name"></i>
                </span>
              </div>
            </div>

            <div class="work-inner">
              <p>Confined Space Access</p>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessrequiredyes" [value]="1" title="Yes" (change)="
                      setValue('1', 'work_permits', 'confined_space_access')
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessrequiredyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessrequiredno" [value]="0" title="No" (change)="
                      setValue('0', 'work_permits', 'confined_space_access')
                    " formControlName="confined_space_access" />
                  <label for="confinedspaceaccessrequiredno">No</label>
                </span>
              </div>

              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="confinedspaceaccessreceivedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        'work_permits',
                        'confined_space_access_recieved'
                      )
                    " formControlName="confined_space_access_recieved" />
                  <label for="confinedspaceaccessreceivedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="confinedspaceaccessreceivedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        'work_permits',
                        'confined_space_access_recieved'
                      )
                    " formControlName="confined_space_access_recieved" />
                  <label for="confinedspaceaccessreceivedno">No</label>
                </span>
              </div>

              <div class="file-upload-wrapper" data-text="Select File to Upload">
                <input name="file-upload-field" type="file" class="file-upload-field"
                  (change)="onFileSelect($event, 'confined_space_access_file')" />
              </div>
              <div class="text-right">
                <span id="confined_space_access_file_name">{{ confined_space_access_file_name }}
                  <i class="fa fa-times pl-2" (click)="removeFile('confined_space_access_file')"
                    *ngIf="confined_space_access_file_name"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-history employee-briefng type-wotk work-permits">
        <h3>Employee Pre-Task Briefing Acknowledgement</h3>

        <div class="wrap">
          <div class="fieldset">
            <div class="field">
              <label class="label" for="name">Supervisor:</label>
              <div class="control">
                <input name="firstname" id="firstname" title="Project:" value="" class="input-text" type="text"
                  data-validate="{required:true}" formControlName="employee_supervisor" aria-required="true" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Date:</label>
              <div class="control">
                <input name="firstname" id="firstname" title="Date:" value="" class="input-text" type="date"
                  data-validate="{required:true}" formControlName="employee_date" aria-required="true" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Supervisor Signature:</label>
              <div class="control">
                <!-- <input name="firstname" id="firstname" title="Job Supervisor/Foreman:" value=""
                                    class="input-text" type="text" data-validate="{required:true}"
                                    formControlName="employee_signature" aria-required="true"> -->

                <img style="width: 100px" src="{{ supervisorSignatureSrc }}" alt="{{ supervisorSignatureSrc }}" />
              </div>
            </div>
          </div>
          <div class="work-wrap">
            <div class="work-inner">
              <label for="beenobtained" class="label">
                <p>Permits have been obtained</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="beenobtainedyes" [value]="1" title="Yes"
                    (change)="setValue('1', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="beenobtainedno" [value]="0" title="No"
                    (change)="setValue('0', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="beenobtainedna" [value]="2" title="NA"
                    (change)="setValue('2', '', 'permits_have_been_obtained')"
                    formControlName="permits_have_been_obtained" />
                  <label for="beenobtainedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="inspectionsperformed" class="label">
                <p>Mobile Equipment Inspections Performed</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="inspectionsperformedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="inspectionsperformedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="inspectionsperformedna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'mobile_equipment_inspections_performed'
                      )
                    " formControlName="mobile_equipment_inspections_performed" />
                  <label for="inspectionsperformedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="equipmentperformed" class="label">
                <p>Pre-Inspections of Powered Tools and Equipment Performed</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="equipmentperformedyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="equipmentperformedno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="equipmentperformedna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'preinspections_of_powered_tools_and_equipment_performed'
                      )
                    " formControlName="preinspections_of_powered_tools_and_equipment_performed" />
                  <label for="equipmentperformedna">N/A</label>
                </span>
              </div>
            </div>
            <div class="work-inner">
              <label for="cardspresent" class="label">
                <p>Mobile Equipment Operator Cards Present</p>
              </label>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="cardspresentyes" [value]="1" title="Yes" (change)="
                      setValue(
                        '1',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentyes">Yes</label>
                </span>
                <span>
                  <input type="radio" id="cardspresentno" [value]="0" title="No" (change)="
                      setValue(
                        '0',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentno">No</label>
                </span>
              </div>
              <div class="redio-wrap">
                <span class="valid">
                  <input type="radio" id="cardspresentna" [value]="2" title="NA" (change)="
                      setValue(
                        '2',
                        '',
                        'mobile_equipment_operator_cards_present'
                      )
                    " formControlName="mobile_equipment_operator_cards_present" />
                  <label for="cardspresentna">N/A</label>
                </span>
              </div>
            </div>
          </div>
          <div class="fieldset employee">
            <div class="field">
              <label class="label" for="name">Employee Name (Type)</label>
              <div class="control">
                <input title="Project:" value="" class="input-text" type="text" aria-required="true"
                  formControlName="employee_signature_name" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Date:</label>
              <div class="control">
                <input title="Date:" value="" class="input-text" type="date" aria-required="true"
                  formControlName="employee_signature_date" />
              </div>
            </div>

            <div class="field">
              <label class="label" for="name">Employee Electronic Signature</label>
              <div class="control">
                <a class="routerLink" (click)="clearSignature()">Clear</a>
                <signature-pad [options]="signaturePadOptions" id="signatureCanvas" (onBeginEvent)="drawBegin()"
                  (onEndEvent)="drawComplete()"></signature-pad>
              </div>
            </div>
          </div>
          <div class="actions-toolbar">
            <div class="primary" *ngIf="checkIfArchived('is_archived') == 0">
              <button type="submit" title="update" class="action submit">
                <span>Update</span>
              </button>
            </div>
            <br />
            <div class="primary">
              <button type="button" title="update" class="action submit"
                (click)="updateFormArchive(checkIfArchived('is_archived'))">
                <span>{{
                  checkIfArchived('is_archived') == 0
                    ? 'Archive Form'
                    : 'Unarchive Form'
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="signature-wrap">
      <h2>Signature History</h2>
      <div class="wrap">
        <div class="fieldset">
          <div class="field">
            <label class="label" for="name">From</label>
            <div class="control">
              <input title="From" value="" class="input-text" type="date" name="start_date" [(ngModel)]="start_date"
                (change)="setDate('start_date')" data-dependent-validation='{"from": "date-to", "prop": "max"}'
                id="date-from" [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>

          <div class="field">
            <label class="label" for="name">To</label>
            <div class="control">
              <input title="To" value="" class="input-text" type="date" name="end_date"
                data-dependent-validation='{"from": "date-from", "prop": "min"}' id="date-to" [(ngModel)]="end_date"
                (change)="setDate('end_date')" [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>
        </div>
        <table style="width: 100%" class="table responsive jobs-data-table">
          <thead>
            <tr>
              <th>Employee Name (Type)</th>
              <th>Employee Electronic Signature</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr style="text-align: center" *ngFor="let item of employeeSignatureData">
              <td class="service-head">{{ item.name }}</td>
              <td class="service-head">
                <img style="width: 100px" alt="{{ item.electronic_signature }}" src="{{ item.electronic_signature }}" />
              </td>
              <td class="service-head">
                {{ item.signature_date | date: 'M/d/yyyy':'UTC' }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mail">
          <label class="label">Email Form</label>
          <input type="email" id="email" #email name="email" class="input-text"
            placeholder="safety@ptscontracting.net" />
          <input id="submit" type="submit" value="Send" class="submit" (click)="sendEmail(email.value)" />
          <div class="mail-sent-text" [innerHTML]="EmailNotificationText"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="add-other-data" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content pb-0">
      <form action="#">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body pb-0 pt-0">
          <div class="pts-contracting">
            <div class="wrap">
              <div class="otherFieldset">
                <div class="field mb-3">
                  <label class="label mb-1" for="name">Description of Work Performed:</label>
                  <div class="control">
                    <input type="text" class="input-text" [(ngModel)]="description_of_work" name="description_of_work">
                  </div>
                </div>

                <div class="field mb-3">
                  <label class="label mb-1" for="name">Hazards Associated with Each Step:</label>
                  <div class="control">
                    <textarea class="resize-vertical input-text" rows="5"
                      [(ngModel)]="hazards_associated_with_each_step"
                      name="hazards_associated_with_each_step" (keypress)='autoExpand($event)'></textarea>
                  </div>
                </div>

                <div class="field mb-3">
                  <label class="label mb-1" for="name">Required Actions to Eliminate or Control the
                    Hazard:</label>
                  <div class="control">
                    <textarea class="resize-vertical input-text" rows="5"
                      [(ngModel)]="required_action_to_eliminate_hazard"
                      name="required_action_to_eliminate_hazard" (keypress)='autoExpand($event)'></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="submitBtn" data-dismiss="modal" #closeOtherModel>Cancel</button>
          <button type="button" class="submitBtn" (click)="submitOther()">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>