<div class="pts-contracting acg-main">
    <div class="container">
        <div class="logo-wrap">
            <a routerLink="/" class="logo"><img src="../../../assets/images/logo.jpg" title="PTS Contracting"
                    alt="PTS Contracting" /></a>
        </div>
        <div class="acg-details">
            <div *ngIf="!generate">
                <div class="info">
                    <div>
                        <div><label class="label" for="name">Current Access Code:
                                {{lastGeneratedCodeDetails['code']}}</label></div>
                        <div><label class="label" for="name">Date Generated:
                                {{getFormattedDate(lastGeneratedCodeDetails['created_at'])}}</label></div>
                    </div>

                </div>
                <div class="text-center"><button type="button" class="submitBtn"
                        (click)="generateCode()">Generate</button></div>
            </div>
            <div *ngIf="generate">
                <div class="new-info">
                    <div><input type="text" [(ngModel)]="newCode" class="input-text" placeholder="Your Access Code"
                            maxlength="6" minlength="6">
                        <span class="error" *ngIf="notValid">Please enter access code</span>
                        <span class="error" *ngIf="notValidPattrn">Please enter alphanumeric value only</span>
                    </div>
                    <div class="pt-20"><label class="label" for="name">Date Generated:
                            {{getFormattedDate(lastGeneratedCodeDetails['created_at'])}}</label></div>

                </div>
                <div class="text-center mb-30">
                    <button type="button" class="submitBtn" (click)="submitCode()">Generate</button>
                </div>
            </div>
        </div>
    </div>
</div>